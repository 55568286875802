import { useful } from "../data";
import top from "../../../assets/images/products/top.png";
import strength from "../../../assets/images/products/strength_vicop.png";
import "../../css/sanpury.css";
export const Vi_strength = () => {
  return (
    <section>
      <div className="bg-white pb-20 base:pb-0 h-auto base:h-screen overflow-hidden flex flex-wrap content-center w-full pt-20 base:pt-24">
        <div className="lg:max-w-screen-lg lg:mx-auto sm:px-6 px-4 base:max-w-none w-full">
          <div className="title relative">
            <img src={top} alt="타이틀박스" className="mx-auto" />
            <h3 className="text-white absolute titleText text-lg md:text-3xl leading-normal md:leading-normal font-bold text-center">
              샌퓨리 바이캅 세정살균티슈 장점
            </h3>
          </div>
          <div className="sectionBody">
            <div className="py-7">
              <img
                src={strength}
                alt="코로나바이러스＆인플루엔자A , 찌든때＆기름때 , 멜트블로운 공법 의 장점을 가졌다는 이미지"
                className="mx-auto max-w-sm w-full"
              />
            </div>
            <div>
              <p className="text-main1 text-lg md:text-3xl leading-normal font-bold text-center md:text-left ">
                샌퓨리 바이캅 세정살균티슈 주요성분{" "}
              </p>
              <ul className="space-y-2 py-4">
                <li className="text-base px-3 md:text-xl ">
                  <svg
                    className="inline-block mr-4 md:w-6 md:h-6 w-3 h-3"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="40" height="40" fill="white" />
                    <rect
                      x="5.72314"
                      y="12.0205"
                      width="18.4876"
                      height="18.4876"
                      fill="white"
                      stroke="#491C78"
                      strokeWidth="3"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M14.0222 29.4876C12.8516 26.2878 8.75477 19.7101 1 13.7847C8.02318 14.377 11.3885 17.2905 12.9979 18.3768C22.2451 7.71066 33.8724 7.66158 38.6033 8.15538C26.7809 8.74795 17.3387 22.3275 14.0222 29.4876Z"
                      fill="#491C78"
                    />
                  </svg>
                  <span className="text-black leading-normal">
                    마스크필터로 사용되는 밀도 높은 "멜트블로운" 원단 사용으로
                    먼지가 밀리지 않고 흡착
                  </span>
                </li>
                <li className="text-base px-3 md:text-xl ">
                  <svg
                    className="inline-block mr-4 md:w-6 md:h-6 w-3 h-3"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="40" height="40" fill="white" />
                    <rect
                      x="5.72314"
                      y="12.0205"
                      width="18.4876"
                      height="18.4876"
                      fill="white"
                      stroke="#491C78"
                      strokeWidth="3"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M14.0222 29.4876C12.8516 26.2878 8.75477 19.7101 1 13.7847C8.02318 14.377 11.3885 17.2905 12.9979 18.3768C22.2451 7.71066 33.8724 7.66158 38.6033 8.15538C26.7809 8.74795 17.3387 22.3275 14.0222 29.4876Z"
                      fill="#491C78"
                    />
                  </svg>
                  <span className="text-black leading-normal">
                    '에탄올' 함유로 얼룩 걱정 없이 깔끔한 마무리
                  </span>
                </li>
                <li className="text-base px-3 md:text-xl ">
                  <svg
                    className="inline-block mr-4 md:w-6 md:h-6 w-3 h-3"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="40" height="40" fill="white" />
                    <rect
                      x="5.72314"
                      y="12.0205"
                      width="18.4876"
                      height="18.4876"
                      fill="white"
                      stroke="#491C78"
                      strokeWidth="3"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M14.0222 29.4876C12.8516 26.2878 8.75477 19.7101 1 13.7847C8.02318 14.377 11.3885 17.2905 12.9979 18.3768C22.2451 7.71066 33.8724 7.66158 38.6033 8.15538C26.7809 8.74795 17.3387 22.3275 14.0222 29.4876Z"
                      fill="#491C78"
                    />
                  </svg>
                  <span className="text-black leading-normal">
                    위험성분인 파라벤, 페녹시에탄올 대신 '소듐조에이드' 사용
                  </span>
                </li>
                <li className="text-base px-3 md:text-xl ">
                  <svg
                    className="inline-block mr-4 md:w-6 md:h-6 w-3 h-3"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="40" height="40" fill="white" />
                    <rect
                      x="5.72314"
                      y="12.0205"
                      width="18.4876"
                      height="18.4876"
                      fill="white"
                      stroke="#491C78"
                      strokeWidth="3"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M14.0222 29.4876C12.8516 26.2878 8.75477 19.7101 1 13.7847C8.02318 14.377 11.3885 17.2905 12.9979 18.3768C22.2451 7.71066 33.8724 7.66158 38.6033 8.15538C26.7809 8.74795 17.3387 22.3275 14.0222 29.4876Z"
                      fill="#491C78"
                    />
                  </svg>
                  <span className="text-black leading-normal">
                    피부 각질 제거 및 모공 협소에 탁월한 천연 방부제
                    '시드락에씨드' 함유
                  </span>
                </li>
                <li className="text-base px-3 md:text-xl ">
                  <svg
                    className="inline-block mr-4 md:w-6 md:h-6 w-3 h-3"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="40" height="40" fill="white" />
                    <rect
                      x="5.72314"
                      y="12.0205"
                      width="18.4876"
                      height="18.4876"
                      fill="white"
                      stroke="#491C78"
                      strokeWidth="3"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M14.0222 29.4876C12.8516 26.2878 8.75477 19.7101 1 13.7847C8.02318 14.377 11.3885 17.2905 12.9979 18.3768C22.2451 7.71066 33.8724 7.66158 38.6033 8.15538C26.7809 8.74795 17.3387 22.3275 14.0222 29.4876Z"
                      fill="#491C78"
                    />
                  </svg>
                  <span className="text-black leading-normal">
                    피부 저자극 '약산성' 산도로 남녀노소 누구나 사용 가능
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
