import { useState } from "react";
import { FOOTER } from "../../layout/kor/footer";
import "../page.css";
import { HEADER } from "../../layout/kor/header";
import { Main } from "../../parts/kor/home/main";
import { Intro } from "../../parts/kor/home/intro";
import { Ci } from "../../parts/kor/home/ci";
import { Sell } from "../../parts/kor/home/sell";
import { Contact } from "../../parts/kor/home/contact";
import ReactFullpage from "@fullpage/react-fullpage";
export const HOME = () => {
  const [headerback, setHeaderBack] = useState<string>("transparent");
  const [response, setresponse] = useState<boolean>(false);
  const headerbackHanlder = (num: number) => {
    if (num === 0) {
      setHeaderBack(() => {
        return "transparent";
      });
    } else {
      setHeaderBack(() => {
        return "#fff";
      });
    }
  };

  const reactvieHandler = () => {
    if (window.innerWidth >= 1024) {
      setresponse(() => {
        return true;
      });
    } else {
      setresponse(() => {
        return false;
      });
    }
  };
  const color = "#332C3A";
  window.addEventListener("load", () => {
    reactvieHandler();
  });
  window.addEventListener("resize", () => {
    reactvieHandler();
  });
  return response ? (
    <main id="content" className="pc banScroll">
      <HEADER value={headerback} color={color} />
      <ReactFullpage
        licenseKey="F6396307-7ED243A8-996C8BAC-85EF3BC3"
        scrollingSpeed={500}
        render={() => {
          return (
            <ReactFullpage.Wrapper>
              <div className="section">
                <Main />
              </div>
              <div className="section">
                <Intro />
              </div>
              <div className="section">
                <Ci />
              </div>
              <div className="section">
                <Sell />
              </div>
              <div className="section">
                <Contact />
              </div>
              <div className="section fp-auto-height">
                <FOOTER />
              </div>
            </ReactFullpage.Wrapper>
          );
        }}
      />
    </main>
  ) : (
    <main id="content" className="mobile">
      <HEADER value={headerback} color={color} />
      {window.addEventListener("scroll", () => {
        if (window.scrollY < 100) {
          headerbackHanlder(0);
        } else {
          headerbackHanlder(9);
        }
      })}
      <Main />
      <Intro />
      <Ci />
      <Sell />

      <Contact />
      <FOOTER />
    </main>
  );
};
