import { useState } from "react";
import { FOOTER } from "../../../layout/kor/footer";
import { SUBHEADER } from "../../../layout/kor/subheader";
import "../../page.css";
import car1 from "../../../assets/images/products/car/car1.jpg";
import car2 from "../../../assets/images/products/car/car2.jpg";
import car3 from "../../../assets/images/products/car/car3.jpg";
import car4 from "../../../assets/images/products/car/car4.jpg";
import car5 from "../../../assets/images/products/car/car5.jpg";
import car6 from "../../../assets/images/products/car/car6.jpg";
import car7 from "../../../assets/images/products/car/car7.jpg";
import car8 from "../../../assets/images/products/car/car8.jpg";
import car9 from "../../../assets/images/products/car/car9.jpg";
import car10 from "../../../assets/images/products/car/car10.jpg";
import car12 from "../../../assets/images/products/car/car12.jpg";
import car13 from "../../../assets/images/products/car/car13.jpg";
import car14 from "../../../assets/images/products/car/car14.jpg";
import car14_gif from "../../../assets/images/products/car/car14.gif";
import car15 from "../../../assets/images/products/car/car15.jpg";
import car16 from "../../../assets/images/products/car/car16.jpg";
import car17 from "../../../assets/images/products/car/car17.jpg";
import car18 from "../../../assets/images/products/car/car18.jpg";
import car19 from "../../../assets/images/products/car/car19.jpg";
import car20 from "../../../assets/images/products/car/car20.jpg";
export const CAR = () => {
  return (
    <main id="content">
      <SUBHEADER />
      <div className="w-full">
        <div className="max-w-screen-lg mx-auto py-20 lg:px-0 px-5 space-y-14">
          <img src={car1} alt="car1" className="w-full" />
          <img src={car2} alt="car2" className="w-full" />
          <img src={car3} alt="car3" className="w-full" />
          <img src={car4} alt="car4" className="w-full" />
          <img src={car5} alt="car5" className="w-full" />
          <img src={car6} alt="car6" className="w-full" />
          <img src={car7} alt="car7" className="w-full" />
          <img src={car9} alt="car9" className="w-full" />
          <img src={car10} alt="car10" className="w-full" />
          <img src={car12} alt="car12" className="w-full" />
          <img src={car13} alt="car13" className="w-full" />
          <img src={car14} alt="car14" className="w-full" />
          <img src={car14_gif} alt="car14_1" className="w-full" />
          <img src={car15} alt="car15" className="w-full" />
          <img src={car16} alt="car16" className="w-full" />
          <img src={car17} alt="car17" className="w-full" />
          <img src={car18} alt="car18" className="w-full" />
          <img src={car19} alt="car19" className="w-full" />
          <img src={car20} alt="car20" className="w-full" />
        </div>
      </div>
      <FOOTER />
    </main>
  );
};
