import React, { useState } from "react";
import nubmer from "../../../assets/images/home/number3_en.png";
// off
import lottemart from "../../../assets/images/home/brand/sell_lottemart.png";
import homeplus from "../../../assets/images/home/brand/sell_homeplus.png";
import ymart from "../../../assets/images/home/brand/sell_ymart.png";
import emart from "../../../assets/images/home/brand/sell_emart.png";
import costco from "../../../assets/images/home/brand/sell_costco.png";
// on
import kakaomarket from "../../../assets/images/home/brand/sell_kakao.png";
import auction from "../../../assets/images/home/brand/sell_auction.png";
import gmarket from "../../../assets/images/home/brand/sell_gmarket.png";
import elevenst from "../../../assets/images/home/brand/sell_11st.png";
import navermarket from "../../../assets/images/home/brand/sell_naver.png";
import makers from "../../../assets/images/home/brand/sell_m.png";
// sns
import instagram from "../../../assets/images/home/brand/sns_instagram.png";
import facebook from "../../../assets/images/home/brand/sns_facebook.png";
import kakao from "../../../assets/images/home/brand/sns_kakao.png";
export const Sell_EN = () => {
  return (
    <section>
      <div className="bg-white h-auto pb-20 base:pb-0 base:h-screen overflow-hidden flex flex-wrap content-center w-full pt-20 base:pt-24">
        <div className="lg:max-w-screen-lg lg:m-auto sm:m-6 m-4 base:max-w-none w-full">
          <div className="sectionHeader pb-8 md:pb-16 flex flex-wrap flex-col base:flex-row base:justify-between">
            <div className="numbering">
              <img
                src={nubmer}
                alt="3 Sales Line"
                className="mx-auto base:m-0 h-36 base:h-auto"
              />
            </div>
            <div className="headText flex flex-col flex-wrap justify-center text-center base:text-right">
              <h2 className="text-2xl sm:text-3xl base:text-4xl leading-normal base:leading-normal font-bold">
                With a wide distribution network
                <br />
                it's convenient to find our products <br />
                anywhere in the country
              </h2>
            </div>
          </div>
          <div className="sectionBody flex flex-col gap-y-8">
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-5 gap-y-5">
              <div className="marketBox">
                <div className="boxHeader h-16 flex flex-wrap content-center justify-center text-lg base:text-2xl leading-normal font-bold">
                  Offline
                </div>
                <div className="boxBody h-36 flex flex-wrap content-center justify-center">
                  <div className="text-center">
                    <img
                      className="inline-block base:m-5 base:h-auto h-5 m-3"
                      src={lottemart}
                      alt="lottemart"
                    />
                    <img
                      className="inline-block base:m-5 base:h-auto h-5 m-3"
                      src={homeplus}
                      alt="homeplue"
                    />
                    <img
                      className="inline-block base:m-5 base:h-auto h-5 m-3"
                      src={ymart}
                      alt="Ymart"
                    />
                    <img
                      className="inline-block base:m-5 base:h-auto h-5 m-3"
                      src={emart}
                      alt="Emart"
                    />
                    <img
                      className="inline-block base:m-5 base:h-auto h-5 m-3"
                      src={costco}
                      alt="costco"
                    />
                  </div>
                </div>
              </div>
              <div className="marketBox">
                <div className="boxHeader h-16 flex flex-wrap content-center justify-center text-lg base:text-2xl leading-normal font-bold">
                  Online
                </div>
                <div className="boxBody h-36 flex flex-wrap content-center justify-center">
                  <div className="text-center">
                    <img
                      className="inline-block base:m-3 base:w-14 w-12 m-2"
                      src={kakaomarket}
                      alt="kakao market"
                    />
                    <img
                      className="inline-block base:m-3 base:w-14 w-12 m-2"
                      src={auction}
                      alt="auction"
                    />
                    <img
                      className="inline-block base:m-3 base:w-14 w-12 m-2"
                      src={gmarket}
                      alt="Gmarket"
                    />
                    <img
                      className="inline-block base:m-3 base:w-14 w-12 m-2"
                      src={elevenst}
                      alt="11st"
                    />
                    <img
                      className="inline-block base:m-3 base:w-14 w-12 m-2"
                      src={navermarket}
                      alt="Naver market"
                    />
                    <img
                      className="inline-block base:m-3 base:w-14 w-12 m-2"
                      src={makers}
                      alt="Makers"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-3 gap-x-5 gap-y-5">
              <div className="marketBox">
                <div className="boxHeader h-16 flex flex-wrap content-center justify-center text-lg base:text-2xl leading-normal font-bold">
                  SNS
                </div>
                <div className="boxBody h-36 flex flex-wrap content-center justify-center">
                  <div className="text-center">
                    <img
                      className="inline-block base:m-3 base:w-14 w-12 m-2"
                      src={instagram}
                      alt="instagram"
                    />
                    <img
                      className="inline-block base:m-3 base:w-14 w-12 m-2"
                      src={facebook}
                      alt="facebook"
                    />
                    <img
                      className="inline-block base:m-3 base:w-14 w-12 m-2"
                      src={kakao}
                      alt="kakaoTalk"
                    />
                  </div>
                </div>
              </div>
              <div className="marketBox">
                <div className="boxHeader h-16 flex flex-wrap content-center justify-center text-lg base:text-2xl leading-normal font-bold">
                  Procurement
                </div>
                <div className="boxBody h-36 flex flex-wrap content-center justify-center">
                  <p className="text-lg base:text-2xl base:space-x-5 space-x-4">
                    <span className="inline-block">KONEPS</span>
                    <span className="inline-block">S2B</span>
                  </p>
                </div>
              </div>
              <div className="marketBox">
                <div className="boxHeader h-16 flex flex-wrap content-center justify-center text-lg base:text-2xl leading-normal font-bold">
                  Export＆Etc
                </div>
                <div className="boxBody h-36 flex flex-wrap content-center justify-center">
                  <span className="px-3 py-1 m-1 text-base base:m-2 base:text-lg base:px-4 base:py-2 inline-block border border-gray-300 base:rounded-2xl rounded-xl ">
                    USA
                  </span>
                  <span className="px-3 py-1 m-1 text-base base:m-2 base:text-lg base:px-4 base:py-2 inline-block border border-gray-300 base:rounded-2xl rounded-xl ">
                    East-South Asia
                  </span>
                  <span className="px-3 py-1 m-1 text-base base:m-2 base:text-lg base:px-4 base:py-2 inline-block border border-gray-300 base:rounded-2xl rounded-xl ">
                    Australia
                  </span>
                  <span className="px-3 py-1 m-1 text-base base:m-2 base:text-lg base:px-4 base:py-2 inline-block border border-gray-300 base:rounded-2xl rounded-xl ">
                    Japen
                  </span>
                  <span className="px-3 py-1 m-1 text-base base:m-2 base:text-lg base:px-4 base:py-2 inline-block border border-gray-300 base:rounded-2xl rounded-xl ">
                    Europe
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
