import "../../page.css";
import soft7080 from "../../../assets/images/products/7080Page_en.jpg";
import { FOOTER_EN } from "../../../layout/eng/footer";
import { SUBHEADER_EN } from "../../../layout/eng/subheader";
export const SOFT7080_EN = () => {
  return (
    <main id="content">
      <SUBHEADER_EN />
      <div className="w-full">
        <div className="max-w-screen-lg mx-auto py-20 lg:px-0 px-5">
          <img
            src={soft7080}
            alt="향긋한 라벤더와 세정살균을 개운하게. 코로나바이러스 박멸! 인플루엔자A 박멸! 8가지 유해세균 박멸! 집안 구석구석 가족을 위한 좋은 습관. 가족이 머무는 거실. 위생이 철저해야 하는 욕실 감염이 직결되는 주방. 우리아이 장난감 매일 누르는 도어락. 매일 사용하는 사무용품. 우리집 반려동물 용품. 집, 사무실, 학원 등 우리가 닿는 모든 물건이라면 샌퓨리 바이캅 세정살균티슈. 당신의 키보드, 현재 세균측정 수치입니다. (측정값이 2079이 적힌 이미지) 매일 샌퓨리 바이캅 세정살균티슈를 사용한다면? (측정값 4가 적힌 이미지) 몸과 마음을 기분 좋게! 샌퓨리 바이캅세정살균티슈. 70매와 80매 제품이 있다. 안전확인대상 생활화학제품 표시사항 - 해당 사항을 알고싶다면 (주)오러스 코리아로 연락주십시오 / "
          />
        </div>
      </div>
      <FOOTER_EN />
    </main>
  );
};
