import { useState } from "react";
import { FOOTER } from "../../../layout/kor/footer";
import { SUBHEADER } from "../../../layout/kor/subheader";
import "../../page.css";
import vicop1 from "../../../assets/images/products/large/vicop01.jpg";
import vicop2 from "../../../assets/images/products/large/vicop02.jpg";
import vicop3 from "../../../assets/images/products/large/vicop03.jpg";
import vicop4 from "../../../assets/images/products/large/vicop04.jpg";
import vicop5 from "../../../assets/images/products/large/vicop05.jpg";
import vicop7 from "../../../assets/images/products/large/vicop07.jpg";
import vicop8 from "../../../assets/images/products/large/vicop08.jpg";
import vicop9 from "../../../assets/images/products/large/vicop09.jpg";
import vicop10 from "../../../assets/images/products/large/vicop10.jpg";
import vicop11 from "../../../assets/images/products/large/vicop11.jpg";
import vicop12 from "../../../assets/images/products/large/vicop12.jpg";
import vicop13 from "../../../assets/images/products/large/vicop13.jpg";
import vicop14 from "../../../assets/images/products/large/vicop14.jpg";
import vicop15 from "../../../assets/images/products/large/vicop15.jpg";
import vicop16 from "../../../assets/images/products/large/vicop16.jpg";
export const LARGE = () => {
  return (
    <main id="content">
      <SUBHEADER />
      <div className="w-full">
        <div className="max-w-screen-lg mx-auto py-20 lg:px-0 px-5 space-y-14">
          <img src={vicop1} alt="car1" className="w-full" />
          <img src={vicop2} alt="car1" className="w-full" />
          <img src={vicop3} alt="car1" className="w-full" />
          <img src={vicop4} alt="car1" className="w-full" />
          <img src={vicop5} alt="car1" className="w-full" />
          <img src={vicop7} alt="car1" className="w-full" />
          <img src={vicop8} alt="car1" className="w-full" />
          <img src={vicop9} alt="car1" className="w-full" />
          <img src={vicop10} alt="car1" className="w-full" />
          <img src={vicop11} alt="car1" className="w-full" />
          <img src={vicop12} alt="car1" className="w-full" />
          <img src={vicop13} alt="car1" className="w-full" />
          <img src={vicop14} alt="car1" className="w-full" />
          <img src={vicop15} alt="car1" className="w-full" />
          <img src={vicop16} alt="car1" className="w-full" />
        </div>
      </div>
      <FOOTER />
    </main>
  );
};
