import "../layout.css";
import allearth from "../../assets/icon/allearth-star.png";
import kakaomarket from "../../assets/images/home/brand/sell_kakao.png";
import navermarket from "../../assets/images/home/brand/sell_naver.png";
import instagram from "../../assets/images/home/brand/sns_instagram.png";
import facebook from "../../assets/images/home/brand/sns_facebook.png";
import kakao from "../../assets/images/home/brand/sns_kakao.png";
export const FOOTER_EN = () => {
  const notice = () => {
    alert("comming soon.");
  };
  return (
    <footer className="w-full py-5 sm:py-10">
      <div className="innerFooter max-w-screen-lg mx-auto">
        <div className="logos flex flex-wrap sm:flex-row flex-col gap-y-5 justify-between">
          <div>
            <img
              src={allearth}
              alt="allearth korea logo"
              className="h-20 mx-auto"
            />
          </div>
          <div className="flex flex-wrap content-center justify-center gap-x-4">
            <a
              target="_blank"
              href="https://www.instagram.com/sanpury_official/"
              className="inline-block w-10 sm:w-14"
            >
              <img src={instagram} alt="instagram logo" className="w-full" />
            </a>
            <a
              target="_blank"
              href="https://www.facebook.com/profile.php?id=100074866286053"
              className="inline-block w-10 sm:w-14"
            >
              <img src={facebook} alt="facebook logo" className="w-full" />
            </a>
            <button onClick={notice} className="inline-block w-10 sm:w-14">
              <img src={kakaomarket} alt="kakao shopping" className="w-full" />
            </button>
            <a
              target="_blank"
              href="https://smartstore.naver.com/sanpury"
              className="inline-block w-10 sm:w-14"
            >
              <img src={navermarket} alt="naver shopping" className="w-full" />
            </a>
            <button onClick={notice} className="inline-block w-10 sm:w-14">
              <img src={kakao} alt="kakako talk" className="w-full" />
            </button>
          </div>
        </div>

        <address className="pt-10 leading-normal not-italic text-center sm:text-left space-y-2 text-sm sm:text-base">
          <p>
            <span className="inline-block rightGuide pr-3">Allearth Korea</span>
            <span className="inline-block rightGuide px-3">
              CEO : Woo-Young Kim
            </span>
            <span className="inline-block px-3">
              Business Number : 550-87-02047
            </span>
            <a
              target="_blank"
              href="https://teht.hometax.go.kr/websquare/websquare.html?w2xPath=/ui/ab/a/a/UTEABAAA13.xml"
              className="inline-block ml-3 border px-2 py-1"
            >
              Business number check
            </a>
          </p>
          <p className="keep-all">
            304-4, 3rd floor, 9 Medivalley-ro, Dong-gu, Daegu City (Daerim-dong,
            GS Building Happy Business Center)
          </p>
          <p>
            <span className="inline-block pr-3 rightGuide">
              registration number : 2021-대구동구-0914
            </span>
            <span className="inline-block px-3 rightGuide">
              Phone :+82 10-6304-8899
            </span>
            <span className="inline-block pl-3 ">
              Email : hahaha1033@nate.com
            </span>
          </p>
          <p>International sales inquiry : hahaha1033@nate.com</p>
          <p>
            <small className="uppercase text-base">
              copyright &copy; make allearth korea. all rights reserved
            </small>
          </p>
        </address>
      </div>
    </footer>
  );
};
