import car from "../../assets/images/sanpury/car.jpg";
import handle from "../../assets/images/sanpury/handle.jpg";
import camp from "../../assets/images/sanpury/camp.jpg";
import floor from "../../assets/images/sanpury/floor.jpg";
import hood from "../../assets/images/sanpury/hood.jpg";
import keyboard from "../../assets/images/sanpury/keyboard.jpg";

export const useful = [
  {
    index: 0,
    useName: "자동차실내",
    image: car,
    desc: "매일 타고 다니는 자동차 소중하게 쓱싹!",
  },
  {
    index: 1,
    useName: "주방후드",
    image: hood,
    desc: "각종 찌든 때와 눌러붙은 기름을 지우는 것엔 이것 만한게 없죠?",
  },
  {
    index: 2,
    useName: "주방조리대",
    image: floor,
    desc: "음식물이 묻었거나, 혹은 기름이 튀었을 주방조리대! 일반 티슈로 될까요? 샌퓨리 바이캅으로 쓱싹!",
  },
  {
    index: 3,
    useName: "캠핑장",
    image: camp,
    desc: "외부에 노출된 환경, 세균과 바이러스에 노출을 막아줄 캠핑 필수템!",
  },
  {
    index: 4,
    useName: "사무실키보드",
    image: keyboard,
    desc: "매일 쓰는 키보드 얼마나 청소하시나요? 손에 세균 바이러스 공유 중지!",
  },
  {
    index: 5,
    useName: "다중시설손잡이",
    image: handle,
    desc: "수 많은 사람들이 잡고 지나갔을 손잡이들, 항균필름만으론 불안감을 떨칠 수가 없죠!",
  },
];

export const branch = [
  {
    index: 0,
    regionName: "광주",
    branchName: "광주 본점",
    region: "o62",
    category: "mart1",
    address: "광주광역시 북구 용봉동 비엔날레로 64",
    contact: "062-527-4852",
  },
  {
    index: 1,
    regionName: "광주",
    branchName: "광주 물류센터 지점",
    region: "o62",
    category: "mart1",
    address: "광주광역시 서구 서광주로 28(매월동)",
    contact: "062-654-5302",
  },
  {
    index: 2,
    regionName: "전남",
    branchName: "영광 법성포점",
    region: "o61",
    category: "mart1",
    address: "전라남도 영광군 법성면 영광로 13",
    contact: "061-356-2122",
  },
  {
    index: 3,
    regionName: "대전",
    branchName: "대전 유성구 지점",
    region: "o42",
    category: "mart1",
    address: "대전광역시 유성구 지족로 295",
    contact: "042-823-4850",
  },
  {
    index: 4,
    regionName: "대전",
    branchName: "대전 대성동 지점",
    region: "o42",
    category: "mart1",
    address: "대전광역시 동구 대전로 332, 지1층 비104호(대성동)",
    contact: "042-284-0990",
  },
];

export const ouput = [
  {
    title: "check 01",
    desc: "코로나바이러스/인플루엔자A 99.99% 제거",
  },
  {
    title: "check 02",
    desc: "8가지 생활 세균 99.9%제거",
  },
  {
    title: "check 03",
    desc: "피부 저자극",
  },
  {
    title: "check 04",
    desc: "기름때&찌든때 강력 제거",
  },
  {
    title: "check 05",
    desc: "멜트블로운 공법 원단 사용",
  },
];
export const review = [
  {
    key: "review1",
    desc: "샌퓨리 바이캅 세정살균티슈 완전 강추해요!!!! 세상 안지워지던 묵은때들이 이렇게나 쉽게 지워지다니! 다른 제품 사서 돈 날리지 말고 당장 써보셔요 청소의 질이 달라집니다",
  },
  {
    key: "review2",

    desc: "티슈, 걸레질을 아무리 깨끗이 해도 닦이지 않는 것도 많고 마지막에 먼지들이 밀려서 청소 두 번 하는 기분이었는데 이 제품은 안 닦이는게 없고 먼지들이 남김없이 티슈에 착 붙어서 너무 좋아요!! 남는건 은은한 라벤더향 뿐~ 재구매 의사 200%입니다!!",
  },
  {
    key: "review3",
    desc: "보이는 곳, 안보이는 곳 닿기만 하면 지워지네요! 속이 다 시원해요 정말 ㅠㅠ 집에 놀러오는 사람들마다 보여줬더니 어디서 샀냐며 알려달라고 난리예요~ 알려줬더니 160매 이 가격 맞냐고 ㅋㅋ 좋은 가격에 좋은 제품 만들어주셔서 감사해요 ㅠㅠ 또 구매하러 올게요~!",
  },
];
