import { useState } from "react";
import "../page.css";
import ReactFullpage from "@fullpage/react-fullpage";
import { FOOTER_EN } from "../../layout/eng/footer";
import { HEADER_EN } from "../../layout/eng/header";
import { Intro_EN } from "../../parts/eng/home/intro";
import { Main_EN } from "../../parts/eng/home/main";
import { Ci_EN } from "../../parts/eng/home/ci";
import { Sell_EN } from "../../parts/eng/home/sell";
import { Contact_EN } from "../../parts/eng/home/contact";
export const HOME_EN = () => {
  const [headerback, setHeaderBack] = useState<string>("transparent");
  const [response, setresponse] = useState<boolean>(false);
  const headerbackHanlder = (num: number) => {
    if (num === 0) {
      setHeaderBack(() => {
        return "transparent";
      });
    } else {
      setHeaderBack(() => {
        return "#fff";
      });
    }
  };

  const reactvieHandler = () => {
    if (window.innerWidth >= 1024) {
      setresponse(() => {
        return true;
      });
    } else {
      setresponse(() => {
        return false;
      });
    }
  };
  const color = "#332C3A";
  window.addEventListener("load", () => {
    reactvieHandler();
  });
  window.addEventListener("resize", () => {
    reactvieHandler();
  });
  return response ? (
    <main id="content" className="pc banScroll">
      <HEADER_EN value={headerback} color={color} />
      <ReactFullpage
        licenseKey="F6396307-7ED243A8-996C8BAC-85EF3BC3"
        scrollingSpeed={500}
        render={() => {
          return (
            <ReactFullpage.Wrapper>
              <div className="section">
                <Main_EN />
              </div>
              <div className="section">
                <Intro_EN />
              </div>
              <div className="section">
                <Ci_EN />
              </div>
              <div className="section">
                <Sell_EN />
              </div>
              <div className="section">
                <Contact_EN />
              </div>
              <div className="section fp-auto-height">
                <FOOTER_EN />
              </div>
            </ReactFullpage.Wrapper>
          );
        }}
      />
    </main>
  ) : (
    <main id="content" className="mobile">
      <HEADER_EN value={headerback} color={color} />
      {window.addEventListener("scroll", () => {
        if (window.scrollY < 100) {
          headerbackHanlder(0);
        } else {
          headerbackHanlder(9);
        }
      })}
      <Main_EN />
      <Intro_EN />
      <Ci_EN />
      <Sell_EN />

      <Contact_EN />
      <FOOTER_EN />
    </main>
  );
};
