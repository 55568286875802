import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper";
import SwiperCore from "swiper/core";
import "swiper/swiper.scss";
import back from "../../../assets/icon/backArrow.png";
SwiperCore.use([Navigation, Pagination, Autoplay]);
export const Slide_EN = () => {
  const arrowback = {
    backgroundColor: "var(--main1)",
  };

  return (
    <div className="bg-main2 pb-20 base:pb-0 h-auto base:h-screen overflow-hidden flex flex-wrap content-center w-full pt-20 base:pt-24">
      <div className="xl:max-w-screen-xl xl:m-auto sm:px-6 px-4 base:max-w-none w-full">
        <p className="max-w-screen-base lg:text-left text-center mx-auto font-bold text-main1 text-xl sm:text-4xl leading-normal sm:leading-normal lg:leading-normal pb-8 text-logo">
          With Sanpury Vi-Cop sanitizing wipes, <br />
          wipe any places safely you touch!
        </p>
        <Swiper
          spaceBetween={0}
          loopAdditionalSlides={1}
          slidesPerView={1}
          speed={450}
          loop={true}
          navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          }}
          pagination={{
            el: ".swiper-pagination",
            type: "bullets",
            clickable: true,
          }}
          className={`pb-10 sm:pb-0`}
        >
          <button
            style={arrowback}
            className="swiper-button-prev f-button-l w-16 h-16 lg:flex flex-wrap content-center justify-center hidden"
          >
            <img src={back} alt="prev" />
          </button>
          <button
            style={arrowback}
            className="swiper-button-next f-button-r w-16 h-16 lg:flex flex-wrap content-center justify-center hidden"
          >
            <img src={back} alt="next" className="transform rotate-180" />
          </button>
          <div className="swiper-pagination absolute bottom-0 z-10 block w-full text-center"></div>
          <SwiperSlide
            className={`flex flex-wrap content-center justify-center`}
          >
            <div className="lg:max-w-screen-base mx-auto w-full">
              <div className="sanpuryvideoRatio relative">
                <video
                  data-keepplaying
                  src="https://sanpury.s3.ap-northeast-2.amazonaws.com/slide1.MP4"
                  autoPlay
                  muted
                  loop
                  className="absolute left-1/2 top-0 h-full transform -translate-x-1/2"
                ></video>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide
            className={`flex flex-wrap content-center justify-center`}
          >
            <div className="lg:max-w-screen-base mx-auto w-full">
              <div className="sanpuryvideoRatio relative">
                <video
                  data-keepplaying
                  src="https://sanpury.s3.ap-northeast-2.amazonaws.com/slide2.mp4"
                  autoPlay
                  muted
                  loop
                  className="absolute left-1/2 top-0 h-full transform -translate-x-1/2"
                ></video>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide
            className={`flex flex-wrap content-center justify-center`}
          >
            <div className="lg:max-w-screen-base mx-auto w-full">
              <div className="sanpuryvideoRatio relative">
                <video
                  data-keepplaying
                  src="https://sanpury.s3.ap-northeast-2.amazonaws.com/slide3.mp4"
                  autoPlay
                  muted
                  loop
                  className="absolute left-1/2 top-0 h-full transform -translate-x-1/2"
                ></video>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
};
