import React, { useState } from "react";
import nubmer from "../../../assets/images/home/number2.png";
import image from "../../../assets/images/home/ci_image.png";
export const Ci = () => {
  return (
    <section>
      <div className="bg-back-gray pb-20 base:pb-0 h-auto base:h-screen overflow-hidden flex flex-wrap content-center w-full pt-20 base:pt-24">
        <div className="lg:max-w-screen-lg lg:m-auto sm:m-6 m-4 base:max-w-none w-full">
          <div className="sectionHeader pb-8 md:pb-16 flex flex-wrap flex-col base:flex-row base:justify-between">
            <div className="numbering">
              <img
                src={nubmer}
                alt="2 CI소개"
                className="mx-auto base:m-0 h-36 base:h-auto"
              />
            </div>
            <div className="headText flex flex-col flex-wrap justify-center text-center base:text-right">
              <h2 className="text-2xl sm:text-3xl lg:text-5xl leading-normal sm:leading-normal lg:leading-normal font-bold">
                <span className="base:block inline-block">좋은 제품은</span>
                <span className="base:block inline-block">
                  (주)오러스코리아부터
                </span>
              </h2>
            </div>
          </div>
          <div className="sectionBody grid grid-cols-1 md:grid-cols-2 base:gap-x-5 sm:gap-x-4 gap-x-2">
            <img
              src={image}
              alt="오러스코리아 ci 이미지 "
              className="max-w-sm md:max-w-none w-full mx-auto"
            />
            <div className="max-w-lg mx-auto pt-4 md:pt-0 text-sm md:text-lg lg:text-2xl space-y-4">
              <p>
                (주)오러스코리아의 모토는 '좋은 제품이 고객에게 전달하는 것'
                좋은 제품은 (주)오러스코리아로부터 나온다는 것이
                (주)오러스코리아의 모토입니다.
              </p>
              <p>
                심볼의 원은 지구, 알파벳'A'는 사람이 걷는 모습을 상징하여
                '지구에 있는 좋은 제품을 우리가 발로 뛰어 찾아 고객에게
                전달한다'는 의미를 담고 있습니다.
              </p>
              <p>
                세개의 별은 (주)오러스코리아의 세 가지 목표인{" "}
                <span className="font-bold">
                  '고객니즈', '고객가치', '고객공감'
                </span>{" "}
                을 의미합니다.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
