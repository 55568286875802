import "../layout.css";
import * as func from "../lay_func";
import { HEADER } from "./header";
import home from "../../assets/images/subheader/home.png";
import { useEffect, useState } from "react";
import { divide, url } from "../../apollo";
import { useReactiveVar } from "@apollo/client";
import { useHistory } from "react-router";
import product from "../../assets/images/subheader/product.jpg";
import productM from "../../assets/images/subheader/productM.jpg";
import branch from "../../assets/images/subheader/branch.jpg";
import branchM from "../../assets/images/subheader/branchM.jpg";
export const SUBHEADER = (props: any) => {
  var pathname = useReactiveVar(url);
  if (pathname.split("/")[2] == "") {
    pathname = func.menu[divide(pathname).mainIndex].sub[0].links;
  }
  var main = pathname.split("/")[1];
  // handler
  const [open, setmenu] = useState<boolean>(true);
  const [subopen, setSubmenu] = useState<boolean>(true);
  let arrow1, arrow2;
  const submenuHandler = () => {
    setSubmenu(() => {
      if (subopen === false) return true;
      return false;
    });
  };
  const menuHandler = () => {
    setmenu(() => {
      if (open === false) return true;
      return false;
    });
  };
  if (open) {
    arrow1 = "▼";
  } else {
    arrow1 = "▲";
  }
  if (subopen) {
    arrow2 = "▼";
  } else {
    arrow2 = "▲";
  }

  // css

  const [headerback, setHeaderBack] = useState<string>("var(--main1)");
  const headerbackHanlder = () => {
    setHeaderBack(() => {
      return "var(--main1)";
    });
  };

  window.addEventListener("load", () => {
    headerbackHanlder();
  });
  return (
    <div>
      <HEADER value={headerback} />
      <div className="subBox">
        <div className="pt-20 base:pt-24 relative text-white bg-main1 ">
          <div className=" w-full base:h-80 h-80 relative">
            {/* <div className="absolute left-0 top-0 w-full h-full bg-opacity-50 bg-gray-700"></div> */}
            <div
              className={`${main === "products" && "prd_back"} ${
                main === "market" && "makt_back"
              } max-w-screen-lg mx-auto w-full h-full flex flex-wrap flex-col justify-center text-center`}
            >
              <h2 className="text-2xl sm:text-4xl font-bold sm:leading-normal leading-normal z-10 text-shadow">
                {divide(pathname).Text}
              </h2>
              <p className="text-xl sm:text-3xl px-5 leading-normal sm:leading-normal z-10 text-shadow">
                {main === "products" && "우리집 평생 지키미"}
                {main === "market" && "전국 인프라구축을 위해 노력합니다"}
              </p>
            </div>
          </div>
          <div id="SNB" className="w-full h-16 bg-main2 text-main1">
            <div className="m-auto max-w-screen-lg h-full text-center lg:flex block lg:flex-wrap lg:content-center">
              <div className="w-14 h-full hidden md:inline-flex  flex-wrap content-center linebar relative">
                <a href="/" className="w-6 block">
                  <img src={home} alt="홈으로가기 버튼" className="w-4/5" />
                </a>
              </div>
              <div className="h-full md:inline-flex flex-wrap w-80 content-center linebar relative hidden">
                <button
                  className="w-full h-full box-border px-10 relative "
                  onClick={menuHandler}
                >
                  <span className="float-left text-lg font-bold">
                    {divide(pathname).mainValue}
                  </span>
                  <span className="float-right text-lg">{arrow1}</span>
                  <ul
                    className={`smm absolute top-16 left-0 w-full text-center bg-white border z-10 ${
                      open && "hidden"
                    }`}
                  >
                    {func.menu.map((obj) => (
                      <li className={`block h-16 w-full`} key={obj.main}>
                        <a
                          href={obj.mainLink}
                          className="text-lg w-full h-full text-black hover:font-bold hover:text-main1 hover:bg-gray-200 flex-wrap flex content-center justify-center "
                        >
                          {obj.main}
                        </a>
                      </li>
                    ))}
                  </ul>
                </button>
              </div>
              {func.menu[divide(pathname).mainIndex].sub.length > 0 && (
                <div className="h-full md:inline-flex inline-block w-full md:w-80 flex-wrap content-center linebar relative">
                  <button
                    className={`w-full h-full box-border px-20 md:px-10 relative  ${
                      subopen ? "border-b-text" : "border-b-button"
                    }`}
                    onClick={submenuHandler}
                  >
                    <span className="md:float-left text-center text-lg font-bold">
                      {divide(pathname).subValue}
                    </span>
                    <span className="float-right text-lg">{arrow2}</span>
                    <ul
                      className={`sms absolute left-0 top-16 w-full text-center bg-white border z-10 ${
                        subopen && "hidden"
                      }`}
                    >
                      {func.menu[divide(pathname).mainIndex].sub.length > 0 &&
                        func.menu[divide(pathname).mainIndex].sub.map((obj) => (
                          <li
                            className={`block h-16 w-full `}
                            key={`${obj.menus}sub`}
                          >
                            <a
                              href={obj.links}
                              className="text-lg w-full h-full hover:font-bold hover:text-main1 hover:bg-gray-200 flex-wrap flex content-center justify-center"
                            >
                              {obj.menus}
                            </a>
                          </li>
                        ))}
                    </ul>
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
