import { useState } from "react";
import { FOOTER } from "../../layout/kor/footer";
import { SUBHEADER } from "../../layout/kor/subheader";
import { Branch } from "../../parts/kor/branch/branch";
import "../page.css";
export const MARKET = () => {
  return (
    <main id="content">
      <SUBHEADER />
      <Branch />
      <FOOTER />
    </main>
  );
};
