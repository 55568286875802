// HEADER
export const menu = [
  {
    main: "오러스코리아",
    sub: [],
    mainLink: "/",
    text: "",
    index: 0,
  },
  {
    main: "샌퓨리 바이캅소개",
    sub: [],
    text: "",
    mainLink: "/sanpury",
    index: 1,
  },
  {
    main: "제품라인업",
    sub: [
      {
        menus: "대용량 캐니스터",
        links: "/products/large",
      },
      {
        menus: "차량용 캐니스터",
        links: "/products/car",
      },
      {
        menus: "소프트팩 70매/80매",
        links: "/products/soft7080",
      },
      {
        menus: "휴대용소프트팩",
        links: "/products/portable",
      },
      {
        menus: "특판행사 및 선물용 키트",
        links: "/products/gift",
      },
    ],
    mainLink: "/products/large",
    text: "샌퓨리 바이캅 세정살균티슈",
    index: 2,
  },
  {
    main: "판매처",
    sub: [],
    index: 3,
    text: "판매처",
    mainLink: "/market",
  },
];
// HEADER
export const menu_en = [
  {
    main: "Allearth Korea",
    sub: [],
    mainLink: "/en",
    text: "",
    index: 0,
  },
  {
    main: "Sanpury Vi-Cop",
    sub: [],
    text: "",
    mainLink: "/en/sanpury",
    index: 1,
  },
  {
    main: "Product Line up",
    sub: [
      {
        menus: "Large quantity of canister",
        links: "/en/products/large",
      },
      {
        menus: "Canister for cars",
        links: "/en/products/car",
      },
      {
        menus: "70/80 pieces of soft packs",
        links: "/en/products/soft7080",
      },
      {
        menus: "Portable soft pack",
        links: "/en/products/portable",
      },
      {
        menus: "For events & For gift",
        links: "/en/products/gift",
      },
    ],
    mainLink: "/en/products/large",
    text: "Sanpury Vi-Cop sanitizing wipes",
    index: 2,
  },
  {
    main: "Stores",
    sub: [],
    index: 3,
    text: "Offline Stores",
    mainLink: "/en/market",
  },
];
