import React, { useState } from "react";
import nubmer from "../../../assets/images/home/number1_en.png";
import image from "../../../assets/images/home/intro_image.png";
export const Intro_EN = () => {
  return (
    <section>
      <div className="bg-white h-auto pb-20 base:pb-0 base:h-screen overflow-hidden flex flex-wrap content-center w-full pt-20 base:pt-24">
        <div className="lg:max-w-screen-lg lg:m-auto sm:m-6 m-4 base:max-w-none w-full">
          <div className="sectionHeader pb-8 md:pb-16 flex flex-wrap flex-col base:flex-row base:justify-between">
            <div className="numbering">
              <img
                src={nubmer}
                alt="Company Introduction"
                className="mx-auto base:m-0 h-36 base:h-auto"
              />
            </div>
            <div className="headText flex flex-col flex-wrap justify-center text-center base:text-right">
              <h2 className="text-2xl sm:text-3xl lg:text-4xl leading-normal lg:leading-normal sm:leading-normal font-bold">
                Company people run for people
              </h2>
              {/* <p className="text-base sm:text-xl lg:text-2xl leading-normal sm:leading-normal lg:leading-normal">
                <span className="capitalize">company</span> people run for
                people
              </p> */}
            </div>
          </div>
          <div className="sectionBody grid grid-cols-1 md:grid-cols-2 base:gap-x-5 sm:gap-x-4 gap-x-2">
            <img
              src={image}
              alt="introducing image "
              className="max-w-sm md:max-w-none w-full mx-auto"
            />
            <div className="max-w-lg mx-auto pt-4 md:pt-0 text-sm md:text-lg lg:text-2xl space-y-4">
              <p>
                Allearth Korea Co., Ltd. is a humanist company that identifies
                the needs of all consumers on the planet, creates honest
                products, and sells them widely.
              </p>
              <p>
                We will always make products transparently and cleanly at a
                realistic price putting ourselves in consumers’ shoes.
              </p>
              <p>
                The goal of Allearth Korea Co., Ltd. is to create a multi-life
                style to win "customer needs," "customer value," and "customer
                empathy."
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
