import { useState } from "react";
import { FOOTER } from "../../../layout/kor/footer";
import { SUBHEADER } from "../../../layout/kor/subheader";
import "../../page.css";
import portable from "../../../assets/images/products/portable.jpg";
export const PORTABLE = () => {
  return (
    <main id="content">
      <SUBHEADER />
      <div className="w-full">
        <div className="max-w-screen-lg mx-auto py-20 lg:px-0 px-5">
          <img
            src={portable}
            alt="밖에서도 나를 지키는 확실한 선택. 코로나바이러스 박멸! 인플루엔자A박멸! 8가지 유해세균 박멸! 밖에서도 나와 소중한 사람을 지키는 샌퓨리 바이캅세정살균티슈 - 휴대용 소프트팩 음식점, 행사장 등 많은 사람이 모이는 곳이라면 1회용 샌퓨리 바이캅세정살균티슈 외출시 필수템 휴대용 바이캅세정살균티슈 5장이라 얇고 딱 필요할때만 집중적으로 쓱~ 자주 만지는 학용품도 수시로 닦고 빌려주고 빌려쓴 학용품도 쓱~ 행사장도, 음식점도 휴대용으로도 샌퓨리 바이캅 세정살균티슈 1매, 5매, 20매 ,25매 상품이 있다. 안전확인대상 생활화학제품 표시사항. 자세한 표기내용은 홈페이지에 기재된 곳으로 연락주세요"
          />
        </div>
      </div>
      <FOOTER />
    </main>
  );
};
