import { useEffect, useState } from "react";
import { FOOTER } from "../../layout/kor/footer";
import ReactPageScroller from "react-page-scroller";
import "../page.css";
import { HEADER } from "../../layout/kor/header";
import { Intro } from "../../parts/kor/sanpury/vicop_intro";
import { Output } from "../../parts/kor/sanpury/vicop_output";
import { Slide } from "../../parts/kor/sanpury/vicop_slide";
import { Useful } from "../../parts/kor/sanpury/vicop_usefull";
import { Instagram } from "../../parts/kor/sanpury/vicop_instagram";
import { Instagram2 } from "../../parts/kor/sanpury/vicop_instagram2";
import ReactFullpage from "@fullpage/react-fullpage";
import { Vi_strength } from "../../parts/kor/sanpury/vicop_strength";

export const SANPURY = () => {
  const [lastscroll, setscroll] = useState<boolean>(false);
  const [headerback, setHeaderBack] = useState<string>("var(--main1)");
  const [response, setresponse] = useState<boolean>(false);
  const headerbackHanlder = () => {
    setHeaderBack(() => {
      return "var(--main1)";
    });
  };
  const banscrollHandler = () => {
    setscroll(() => {
      return false;
    });
  };
  const okscrollHandler = () => {
    document.body.classList.add("noScrollBar");
    setscroll(() => {
      return true;
    });
  };
  const reactvieHandler = () => {
    if (window.innerWidth >= 1024) {
      setresponse(() => {
        return true;
      });
    } else {
      setresponse(() => {
        return false;
      });
    }
  };
  window.addEventListener("load", () => {
    headerbackHanlder();
    reactvieHandler();
  });
  window.addEventListener("resize", () => {
    reactvieHandler();
  });
  return response ? (
    <main id="content" className="pc banScroll">
      <HEADER value={headerback} />
      <ReactFullpage
        licenseKey="F6396307-7ED243A8-996C8BAC-85EF3BC3"
        scrollingSpeed={500}
        render={() => {
          return (
            <ReactFullpage.Wrapper>
              <div className="section">
                <Intro />
              </div>
              <div className="section">
                <Output />
              </div>
              <div className="section">
                <Slide />
              </div>
              <div className="section">
                <Vi_strength />
              </div>
              <div className="section">
                <Useful />
              </div>
              <div className="section">
                <Instagram />
              </div>
              <div className="section fp-auto-height">
                <FOOTER />
              </div>
            </ReactFullpage.Wrapper>
          );
        }}
      />
    </main>
  ) : (
    <main id="content" className="mobile">
      <HEADER value={headerback} />
      <Intro />
      <Output />
      <Slide />
      <Vi_strength />
      <Useful />
      <Instagram2 />
      <FOOTER />
    </main>
  );
};
