import { useful } from "../data";
import top from "../../../assets/images/products/top.png";
import strength from "../../../assets/images/products/strength_vicop_en.png";
import "../../css/sanpury.css";
export const Vi_strength_EN = () => {
  return (
    <section>
      <div className="bg-white pb-20 base:pb-0 h-auto base:h-screen overflow-hidden flex flex-wrap content-center w-full pt-20 base:pt-24">
        <div className="lg:max-w-screen-lg lg:mx-auto sm:px-6 px-4 base:max-w-none w-full">
          <div className="title relative">
            <img src={top} alt="title box" className="mx-auto" />
            <h3 className="text-white absolute titleText text-lg md:text-3xl leading-normal md:leading-normal font-bold text-center">
              Product Strength
            </h3>
          </div>
          <div className="sectionBody">
            <div className="py-7">
              <img
                src={strength}
                alt="Product Strength Image :  Removing of Coronavirus and Influenza A, Powerful removal of oil and dirt , Meltblown fabric"
                className="mx-auto max-w-sm w-full"
              />
            </div>
            <div>
              <p className="text-main1 text-lg md:text-3xl leading-normal font-bold text-center md:text-left ">
                Sanpury Vi-cop Sanitizing Wipes’s main substances
              </p>
              <ul className="space-y-2 py-4">
                <li className="text-base px-3 md:text-xl ">
                  <svg
                    className="inline-block mr-4 md:w-6 md:h-6 w-3 h-3"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="40" height="40" fill="white" />
                    <rect
                      x="5.72314"
                      y="12.0205"
                      width="18.4876"
                      height="18.4876"
                      fill="white"
                      stroke="#491C78"
                      strokeWidth="3"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M14.0222 29.4876C12.8516 26.2878 8.75477 19.7101 1 13.7847C8.02318 14.377 11.3885 17.2905 12.9979 18.3768C22.2451 7.71066 33.8724 7.66158 38.6033 8.15538C26.7809 8.74795 17.3387 22.3275 14.0222 29.4876Z"
                      fill="#491C78"
                    />
                  </svg>
                  <span className="text-black leading-normal">
                    It uses ‘melt-blown fabric’ with high adhesion power that is
                    used in air purifier HEPA filters and water purifier
                    filters.
                  </span>
                </li>
                <li className="text-base px-3 md:text-xl ">
                  <svg
                    className="inline-block mr-4 md:w-6 md:h-6 w-3 h-3"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="40" height="40" fill="white" />
                    <rect
                      x="5.72314"
                      y="12.0205"
                      width="18.4876"
                      height="18.4876"
                      fill="white"
                      stroke="#491C78"
                      strokeWidth="3"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M14.0222 29.4876C12.8516 26.2878 8.75477 19.7101 1 13.7847C8.02318 14.377 11.3885 17.2905 12.9979 18.3768C22.2451 7.71066 33.8724 7.66158 38.6033 8.15538C26.7809 8.74795 17.3387 22.3275 14.0222 29.4876Z"
                      fill="#491C78"
                    />
                  </svg>
                  <span className="text-black leading-normal">
                    It contains ‘ethanol’ and can be cleaned neatly without
                    stains.
                  </span>
                </li>
                <li className="text-base px-3 md:text-xl ">
                  <svg
                    className="inline-block mr-4 md:w-6 md:h-6 w-3 h-3"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="40" height="40" fill="white" />
                    <rect
                      x="5.72314"
                      y="12.0205"
                      width="18.4876"
                      height="18.4876"
                      fill="white"
                      stroke="#491C78"
                      strokeWidth="3"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M14.0222 29.4876C12.8516 26.2878 8.75477 19.7101 1 13.7847C8.02318 14.377 11.3885 17.2905 12.9979 18.3768C22.2451 7.71066 33.8724 7.66158 38.6033 8.15538C26.7809 8.74795 17.3387 22.3275 14.0222 29.4876Z"
                      fill="#491C78"
                    />
                  </svg>
                  <span className="text-black leading-normal">
                    It uses ‘sodium benzoate’ instead of parabens and
                    phenoxyethanol which are dangerous ingredients.
                  </span>
                </li>
                <li className="text-base px-3 md:text-xl ">
                  <svg
                    className="inline-block mr-4 md:w-6 md:h-6 w-3 h-3"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="40" height="40" fill="white" />
                    <rect
                      x="5.72314"
                      y="12.0205"
                      width="18.4876"
                      height="18.4876"
                      fill="white"
                      stroke="#491C78"
                      strokeWidth="3"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M14.0222 29.4876C12.8516 26.2878 8.75477 19.7101 1 13.7847C8.02318 14.377 11.3885 17.2905 12.9979 18.3768C22.2451 7.71066 33.8724 7.66158 38.6033 8.15538C26.7809 8.74795 17.3387 22.3275 14.0222 29.4876Z"
                      fill="#491C78"
                    />
                  </svg>
                  <span className="text-black leading-normal">
                    It contains 'Citric Acid', a natural raw material that has
                    an effect of alleviating skin irritation
                  </span>
                </li>
                <li className="text-base px-3 md:text-xl ">
                  <svg
                    className="inline-block mr-4 md:w-6 md:h-6 w-3 h-3"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="40" height="40" fill="white" />
                    <rect
                      x="5.72314"
                      y="12.0205"
                      width="18.4876"
                      height="18.4876"
                      fill="white"
                      stroke="#491C78"
                      strokeWidth="3"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M14.0222 29.4876C12.8516 26.2878 8.75477 19.7101 1 13.7847C8.02318 14.377 11.3885 17.2905 12.9979 18.3768C22.2451 7.71066 33.8724 7.66158 38.6033 8.15538C26.7809 8.74795 17.3387 22.3275 14.0222 29.4876Z"
                      fill="#491C78"
                    />
                  </svg>
                  <span className="text-black leading-normal">
                    It is hypoallergenic and weak in acid for the skin, so
                    anyone can use it.
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
