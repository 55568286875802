import { useState } from "react";
import { FOOTER } from "../../../layout/kor/footer";
import { SUBHEADER } from "../../../layout/kor/subheader";
import "../../page.css";
import soft1 from "../../../assets/images/products/7080/soft1.jpg";
import soft2 from "../../../assets/images/products/7080/soft2.jpg";
import soft3 from "../../../assets/images/products/7080/soft3.jpg";
import soft4 from "../../../assets/images/products/7080/soft4.jpg";
import soft5 from "../../../assets/images/products/7080/soft5.jpg";
import soft6 from "../../../assets/images/products/7080/soft6.jpg";
import soft7 from "../../../assets/images/products/7080/soft7.jpg";
import soft8 from "../../../assets/images/products/7080/soft7.jpg";
import soft9 from "../../../assets/images/products/7080/soft9.jpg";
import soft9_1 from "../../../assets/images/products/7080/soft9_1.jpg";
import soft10 from "../../../assets/images/products/7080/soft10.jpg";
import soft12 from "../../../assets/images/products/7080/soft12.jpg";
import soft13 from "../../../assets/images/products/7080/soft13.jpg";
import soft14 from "../../../assets/images/products/7080/soft14.jpg";
import soft15 from "../../../assets/images/products/7080/soft15.jpg";
import soft16 from "../../../assets/images/products/7080/soft16.jpg";
import soft17 from "../../../assets/images/products/7080/soft17.jpg";
import soft18 from "../../../assets/images/products/7080/soft18.jpg";
import soft19 from "../../../assets/images/products/7080/soft19.jpg";

export const SOFT7080 = () => {
  return (
    <main id="content">
      <SUBHEADER />
      <div className="w-full">
        <div className="max-w-screen-lg mx-auto py-20 lg:px-0 px-5 space-y-14">
          <img src={soft1} alt="soft1" className="w-full" />
          <img src={soft2} alt="soft2" className="w-full" />
          <img src={soft3} alt="soft3" className="w-full" />
          <img src={soft4} alt="soft4" className="w-full" />
          <img src={soft5} alt="soft5" className="w-full" />
          <img src={soft6} alt="soft6" className="w-full" />
          <img src={soft7} alt="soft7" className="w-full" />
          <img src={soft8} alt="soft8" className="w-full" />
          <img src={soft9} alt="soft9" className="w-full" />
          <img src={soft9_1} alt="soft9_1" className="w-full" />
          <img src={soft10} alt="soft10" className="w-full" />
          <img src={soft12} alt="soft12" className="w-full" />
          <img src={soft13} alt="soft13" className="w-full" />
          <img src={soft14} alt="soft14" className="w-full" />
          <img src={soft15} alt="soft15" className="w-full" />
          <img src={soft16} alt="soft16" className="w-full" />
          <img src={soft17} alt="soft17" className="w-full" />
          <img src={soft18} alt="soft18" className="w-full" />
          <img src={soft19} alt="soft19" className="w-full" />
        </div>
      </div>
      <FOOTER />
    </main>
  );
};
