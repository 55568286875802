import { useEffect, useRef, useState, useLayoutEffect } from "react";
import corona from "../../../assets/images/sanpury/corona.png";
import vicop from "../../../assets/images/sanpury/vicop.png";
import { Fade } from "react-awesome-reveal";

// export
export function useInterval(callback: () => void, delay: number | null) {
  const savedCallback = useRef(callback);
  useLayoutEffect(() => {
    savedCallback.current = callback;
  }, [callback]);
  useEffect(() => {
    if (!delay && delay !== 0) {
      return;
    }
    const id = setInterval(() => savedCallback.current(), delay);
    return () => clearInterval(id);
  }, [delay]);
}
export const Intro_EN = () => {
  const [tnum, setTnum] = useState<number>(0);
  const tnumHandler = (num: number) => {
    setTnum(() => {
      return num;
    });
  };
  return (
    <section>
      <h1 className="hidden">Sanpury Vi-Cop sanitizing wipes</h1>
      <div className="h-screen overflow-hidden flex flex-wrap content-center w-full ">
        <video
          data-keepplaying
          src="https://sanpury.s3.ap-northeast-2.amazonaws.com/last_video.mp4"
          muted
          autoPlay
          loop
          className="w-full h-full object-cover mainVideo"
          onTimeUpdate={(e) => {
            let progress = e.currentTarget.currentTime;
            if (progress < 0.5) {
              tnumHandler(0);
            } else if (progress > 3.5 && progress < 10.25) {
              tnumHandler(9);
            } else if (progress > 10.28 && progress < 15.19) {
              tnumHandler(1);
            } else if (progress > 15.19) {
              tnumHandler(2);
            }
          }}
        ></video>
        <div
          className="absolute left-0 top-0 w-full h-screen bg-white mix-blend-soft-light opacity-50
        "
        ></div>
        <div className="absolute left-0 top-0 w-full h-full">
          <div className="intro_text w-full h-full mx-auto max-w-screen-lg px-5 lg:px-0 relative">
            {tnum === 0 && (
              <Fade
                triggerOnce
                className="inklip absolute textTop1 left-0 w-full"
              >
                <p className="text-2xl lg:text-7xl text-blacks leading-normal lg:leading-normal text-center">
                  Mild ingredients! Strong cleaning!
                </p>
              </Fade>
            )}
            {tnum === 1 && (
              <Fade
                triggerOnce
                className="inklip absolute textTop1 left-0 w-full"
              >
                <p className="text-2xl lg:text-7xl text-blacks leading-normal lg:leading-normal text-center">
                  Mild ingredients! Strong cleaning!
                </p>
              </Fade>
            )}
            {tnum === 2 && (
              <Fade
                triggerOnce
                className="inklip absolute textTop2 left-0 w-full"
              >
                <h2 className="capitalize lg:leading-normal  leading-normal text-5xl lg:text-8xl text-blacks text-center font-bold">
                  you must <span className="uppercase">vi-cop</span>
                </h2>
              </Fade>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};
