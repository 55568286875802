import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import * as React from "react";
import { HOME } from "../page/kor/home";
import { SANPURY } from "../page/kor/sanpury";
import { MARKET } from "../page/kor/branch";
import { CAR } from "../page/kor/products/car";
import { SOFT7080 } from "../page/kor/products/soft7080";
import { PORTABLE } from "../page/kor/products/portable";
import { LARGE } from "../page/kor/products/large";
import { GIFT } from "../page/kor/products/gift";
// eng
import { HOME_EN } from "../page/eng/home";
import { SANPURY_EN } from "../page/eng/sanpury";
import { MARKET_EN } from "../page/eng/branch";
import { SOFT7080_EN } from "../page/eng/products/soft7080";
import { PORTABLE_EN } from "../page/eng/products/portable";
import { LARGE_EN } from "../page/eng/products/large";
import { CAR_EN } from "../page/eng/products/car";
import { GIFT_EN } from "../page/eng/products/gift";
const Root: React.FC = () => (
  <Router>
    <Switch>
      <Route path="/" exact component={HOME} />
      <Route path="/sanpury" component={SANPURY} />
      <Route path="/products/large" component={LARGE} />
      <Route path="/products/car" component={CAR} />
      <Route path="/products/soft7080" component={SOFT7080} />
      <Route path="/products/portable" component={PORTABLE} />
      <Route path="/products/gift" component={GIFT} />
      <Redirect path="/products" to="/products/large" />
      <Route path="/market" component={MARKET} />
      {/* eng */}
      <Route path="/en" exact component={HOME_EN} />
      <Route path="/en/sanpury" component={SANPURY_EN} />
      <Route path="/en/products/large" component={LARGE_EN} />
      <Route path="/en/products/car" component={CAR_EN} />
      <Route path="/en/products/soft7080" component={SOFT7080_EN} />
      <Route path="/en/products/portable" component={PORTABLE_EN} />
      <Route path="/en/products/gift" component={GIFT_EN} />
      <Redirect path="/en/products" to="/en/products/large" />
      <Route path="/en/market" component={MARKET_EN} />
    </Switch>
  </Router>
);

export default Root;
