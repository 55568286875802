import "../../css/sanpury.css";
import { ouput } from "../data";
export const Output = () => {
  return (
    <section>
      <div className="h-screen overflow-hidden flex flex-wrap content-center w-full relative">
        <video
          data-keepplaying
          src="https://sanpury.s3.ap-northeast-2.amazonaws.com/sanpury_vicop.mp4"
          muted
          autoPlay
          loop
          className="w-full h-full object-cover"
        ></video>
        <div className="lg:hidden block absolute w-full h-full left-0 top-0 bg-gray-300 bg-opacity-50"></div>
        <div className="absolute left-0 top-0 w-full h-full">
          <div className="max-w-screen-lg h-full mx-auto flex flex-wrap justify-center flex-col px-5">
            <div className="w-64 space-y-5">
              {ouput.map((obj) => (
                <div key={obj.title} className="space-y-1">
                  <p className="chk text-main1 text-2xl font-bold">
                    {obj.title}
                  </p>
                  <p className="desc text-blacks text-xl font-bold keep-all leading-tight">
                    {obj.desc}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
