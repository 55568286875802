import React, { useEffect, useState } from "react";
import instagram from "../../../assets/images/home/brand/sns_instagram.png";
import "../../css/sanpury.css";
import Instafeed from "../../../instafeed";

export const Instagram2 = () => {
  useEffect(() => {
    var feed2 = new Instafeed({
      target: "insta",
      template:
        '<div className="feedRaio bg-main1"><a href="{{link}}" className="absolute left-0 top-0 w-full h-full"><img title="{{caption}}" src="{{image}}" className="w-full h-full"/></a></div>',
      limit: 8,
      accessToken:
        "IGQVJVZA2t3U3hYaG5VRl9kTlJxSjFRSEljRHdzSlhXSlNzeWE5SEhtQ04zbFl3dTZAKQkxSM19uNXduU0R0N2sxSWhscXdlMnJIcUpZAVDg3UHpjb3pFYndtci1OOXBHbHBXYk1ReTRBYTc0dXNmN3otYgZDZD",
    });
    feed2.run();
  }, []);
  return (
    <section>
      <div className="bg-white pb-20 base:pb-0 h-auto base:h-screen overflow-hidden flex flex-wrap content-center w-full pt-20 base:pt-24">
        <div className="lg:max-w-screen-lg lg:mx-auto sm:px-6 px-4 base:max-w-none w-full">
          <div className="sectionHeader pb-8 md:pb-20 flex flex-wrap justify-center">
            <img src={instagram} alt="인스타그램 로고" />
          </div>
          <div className="sectionBody relative">
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-main2 w-screen h-1/2"></div>
            <div
              id="insta"
              className="relative grid grid-cols-2 md:grid-cols-4 base:gap-x-6 sm:gap-x-4 gap-x-2 gap-y-6"
            ></div>
          </div>
        </div>
      </div>
    </section>
  );
};
