import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper";
import SwiperCore from "swiper/core";
import "swiper/swiper.scss";
import back from "../../../assets/icon/backArrow.png";
import nubmer from "../../../assets/images/home/number4.png";
import allearth from "../../../assets/images/home/allearthkorea.svg";
import ninano from "../../../assets/icon/ninano_orignal.png";
SwiperCore.use([Navigation, Pagination, Autoplay]);

const arrowback = {
  backgroundColor: "#5CC2D0",
};
export const Contact_EN = () => {
  return (
    <div className="bg-back-gray pb-20 base:pb-0 h-auto base:h-screen overflow-hidden flex flex-wrap content-center w-full pt-20 base:pt-24">
      <div className="xl:max-w-screen-xl xl:m-auto sm:px-6 px-4 base:max-w-none w-full">
        <Swiper
          spaceBetween={0}
          loopAdditionalSlides={1}
          slidesPerView={1}
          speed={450}
          loop={true}
          navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          }}
          pagination={{
            el: ".swiper-pagination",
            type: "bullets",
            clickable: true,
          }}
          className={`relative pb-10 sm:pb-0`}
        >
          <button
            style={arrowback}
            className="swiper-button-prev f-button-l w-16 h-16 lg:flex flex-wrap content-center justify-center hidden"
          >
            <img src={back} alt="prev" />
          </button>
          <button
            style={arrowback}
            className="swiper-button-next f-button-r w-16 h-16 lg:flex flex-wrap content-center justify-center hidden"
          >
            <img src={back} alt="next" className="transform rotate-180" />
          </button>
          <div className="swiper-pagination absolute bottom-0 z-10 lg:hidden block w-full text-center"></div>
          <SwiperSlide
            className={`flex flex-wrap content-center justify-center`}
          >
            <div className="max-w-screen-lg">
              <div className="sectionHeader pb-8 md:pb-16 flex flex-wrap flex-col base:flex-row base:justify-between">
                <div className="numbering">
                  <img
                    src={nubmer}
                    alt="4 contact us"
                    className="mx-auto base:m-0 h-36 base:h-auto"
                  />
                </div>
                <div className="headText flex flex-col flex-wrap justify-center text-center base:text-right">
                  <h2 className="text-3xl lg:text-5xl leading-normal font-bold">
                    HeadQuarters
                  </h2>
                </div>
              </div>
              <div className="sectionBody grid grid-cols-1 sm:grid-cols-2 base:gap-x-5 sm:gap-x-4 gap-x-2">
                <div className="max-w-sm md:max-w-none w-full mx-auto">
                  <div className="brachMapRatio relative">
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d808.2543924729486!2d128.74889852922675!3d35.87308628547943!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x35660c90dbaf7219%3A0xf517605329e7d870!2z64yA6rWs6rSR7Jet7IucIOuPmeq1rCDslYjsi6wzLjTrj5kgMTIwLTQ!5e0!3m2!1sko!2skr!4v1631069308042!5m2!1sko!2skr"
                      allowFullScreen
                      loading="lazy"
                      className="w-full h-full absolute left-0 top-0"
                    ></iframe>
                  </div>
                </div>
                <div className="text-sm sm:text-lg lg:text-2xl space-y-4 relative">
                  <p className="absolute sm:static right-5 bottom-8">
                    <img
                      src={allearth}
                      alt="allearth korea"
                      className="w-20 sm:w-1/3 base:w-56"
                    />
                  </p>
                  <p className="keep-all">
                    304-4, 3rd floor, 9 Medivalley-ro, Dong-gu, Daegu City
                    (Daerim-dong, GS Building Happy Business Center)
                  </p>
                  <p>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="mr-5 inline-block"
                    >
                      <path
                        d="M18.1668 14.3797C17.4951 14.2352 16.9637 14.5471 16.4932 14.8194C16.0114 15.1001 15.0951 15.8434 14.57 15.6531C11.8811 14.546 9.35213 12.1925 8.25739 9.49286C8.06444 8.95647 8.80424 8.0344 9.08283 7.54686C9.35316 7.07492 9.65858 6.53852 9.51928 5.86178C9.3934 5.25366 7.76522 3.18188 7.18947 2.61533C6.80977 2.2411 6.42078 2.03527 6.02147 2.00201C4.5202 1.93756 2.84352 3.94073 2.54945 4.41996C1.81274 5.44181 1.81687 6.80152 2.56183 8.45021C4.35717 12.8786 11.1475 19.5618 15.5925 21.4246C16.4128 21.8082 17.1629 22.0005 17.8366 22.0005C18.496 22.0005 19.0831 21.8165 19.5876 21.4516C19.9684 21.2323 22.0536 19.4724 21.9989 17.9307C21.9659 17.5378 21.7606 17.1448 21.3912 16.7644C20.8289 16.1833 18.7704 14.5065 18.1668 14.3797Z"
                        fill="#242424"
                      />
                    </svg>
                    <span>+82 10-6304-8899</span>
                  </p>
                  <p>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="mr-5 inline-block"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M14.3558 2.00793C14.1328 1.97595 13.9087 2.04191 13.7304 2.18381C13.5472 2.32771 13.4326 2.53557 13.4077 2.76841C13.355 3.23908 13.6946 3.66479 14.1646 3.71776C17.4063 4.07951 19.9259 6.60477 20.2904 9.85654C20.3392 10.2922 20.7047 10.621 21.1409 10.621C21.1738 10.621 21.2057 10.619 21.2385 10.615C21.4666 10.59 21.6697 10.4771 21.8132 10.2972C21.9556 10.1174 22.0203 9.89351 21.9944 9.66467C21.5403 5.60746 18.4002 2.45862 14.3558 2.00793ZM14.4181 5.48994C13.942 5.402 13.5048 5.70579 13.4142 6.17047C13.3236 6.63515 13.6283 7.08884 14.0914 7.17978C15.4857 7.45159 16.5623 8.53085 16.8351 9.92989V9.93089C16.9128 10.3336 17.2674 10.6264 17.6757 10.6264C17.7305 10.6264 17.7852 10.6214 17.841 10.6114C18.3041 10.5185 18.6088 10.0658 18.5182 9.60012C18.1109 7.51055 16.5025 5.89666 14.4181 5.48994ZM16.0001 15.0905C16.4515 14.8302 16.9615 14.5362 17.6047 14.673C18.1873 14.7959 20.1731 16.4078 20.7169 16.9665C21.0734 17.3322 21.2716 17.7099 21.3025 18.0877C21.3562 19.5697 19.3425 21.2615 18.976 21.4724C18.489 21.8231 17.9224 22 17.286 22C16.6357 22 15.9106 21.8151 15.1199 21.4464C10.8296 19.6556 4.27553 13.231 2.54266 8.97395C1.82362 7.38903 1.81864 6.08193 2.53071 5.09961C2.81454 4.63892 4.43288 2.71325 5.88192 2.7742C6.26733 2.80718 6.64179 3.00505 7.00928 3.3648C7.56499 3.90942 9.13653 5.90106 9.25803 6.48565C9.39247 7.13521 9.09769 7.65185 8.83576 8.10554C8.7799 8.20307 8.70463 8.31881 8.62217 8.4456C8.3092 8.92683 7.89264 9.56735 8.04004 9.97626C9.09669 12.5705 11.5376 14.8339 14.133 15.8972C14.5343 16.0431 15.1745 15.6235 15.6542 15.3092C15.7791 15.2273 15.8932 15.1525 15.9893 15.0967L16.0001 15.0905Z"
                        fill="#242424"
                      />
                    </svg>
                    <span>+82 10.3828.1033</span>
                  </p>
                  <p>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="mr-5 inline-block"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M16.9395 3C18.2805 3 19.5705 3.53 20.5195 4.481C21.4695 5.43 22.0005 6.71 22.0005 8.05V15.95C22.0005 18.74 19.7305 21 16.9395 21H7.06049C4.26949 21 2.00049 18.74 2.00049 15.95V8.05C2.00049 5.26 4.25949 3 7.06049 3H16.9395ZM18.5305 9.54L18.6105 9.46C18.8495 9.17 18.8495 8.75 18.5995 8.46C18.4605 8.311 18.2695 8.22 18.0705 8.2C17.8605 8.189 17.6605 8.26 17.5095 8.4L13.0005 12C12.4205 12.481 11.5895 12.481 11.0005 12L6.50049 8.4C6.18949 8.17 5.75949 8.2 5.50049 8.47C5.23049 8.74 5.20049 9.17 5.42949 9.47L5.56049 9.6L10.1105 13.15C10.6705 13.59 11.3495 13.83 12.0605 13.83C12.7695 13.83 13.4605 13.59 14.0195 13.15L18.5305 9.54Z"
                        fill="#242424"
                      />
                    </svg>

                    <span>hahaha1033@nate.com</span>
                  </p>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide
            className={`flex flex-wrap content-center justify-center`}
          >
            <div className="max-w-screen-lg w-full">
              <div className="sectionHeader pb-8 md:pb-16 flex flex-wrap flex-col base:flex-row base:justify-between">
                <div className="numbering">
                  <img
                    src={nubmer}
                    alt="4 contact us"
                    className="mx-auto base:m-0 h-36 base:h-auto"
                  />
                </div>
                <div className="headText flex flex-col flex-wrap justify-center text-center base:text-right">
                  <h2 className="text-3xl lg:text-5xl leading-normal font-bold">
                    Yeongnam Branch
                  </h2>
                </div>
              </div>
              <div className="sectionBody grid grid-cols-1 sm:grid-cols-2 base:gap-x-5 sm:gap-x-4 gap-x-2">
                <div className="max-w-sm md:max-w-none w-full mx-auto">
                  <div className="brachMapRatio relative">
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d810.1128534148243!2d128.45549006025067!3d35.69050760857432!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x356f598c9103b559%3A0x234394f531044747!2z64yA6rWs7YWM7YGs67mE7KaI7IS87YSw!5e0!3m2!1sko!2skr!4v1640146818212!5m2!1sko!2skr"
                      loading="lazy"
                      allowFullScreen
                      className="w-full h-full absolute left-0 top-0"
                    ></iframe>
                  </div>
                </div>
                <div className="text-sm sm:text-lg lg:text-2xl space-y-4 relative">
                  <p className="keep-all pt-5 sm:pt-20">
                    EF Research Institute of Human Ecology Inc., Room No. 15,
                    Daegu Tech Business Center, 16, Techno gongwon-ro,
                    Hyeonpung-eup, Dalseong-gun, Daegu
                  </p>
                  <p>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="mr-5 inline-block"
                    >
                      <path
                        d="M18.1668 14.3797C17.4951 14.2352 16.9637 14.5471 16.4932 14.8194C16.0114 15.1001 15.0951 15.8434 14.57 15.6531C11.8811 14.546 9.35213 12.1925 8.25739 9.49286C8.06444 8.95647 8.80424 8.0344 9.08283 7.54686C9.35316 7.07492 9.65858 6.53852 9.51928 5.86178C9.3934 5.25366 7.76522 3.18188 7.18947 2.61533C6.80977 2.2411 6.42078 2.03527 6.02147 2.00201C4.5202 1.93756 2.84352 3.94073 2.54945 4.41996C1.81274 5.44181 1.81687 6.80152 2.56183 8.45021C4.35717 12.8786 11.1475 19.5618 15.5925 21.4246C16.4128 21.8082 17.1629 22.0005 17.8366 22.0005C18.496 22.0005 19.0831 21.8165 19.5876 21.4516C19.9684 21.2323 22.0536 19.4724 21.9989 17.9307C21.9659 17.5378 21.7606 17.1448 21.3912 16.7644C20.8289 16.1833 18.7704 14.5065 18.1668 14.3797Z"
                        fill="#242424"
                      />
                    </svg>
                    <span>+82 10.2523.1789</span>
                  </p>
                  <p>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="mr-5 inline-block"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M14.3558 2.00793C14.1328 1.97595 13.9087 2.04191 13.7304 2.18381C13.5472 2.32771 13.4326 2.53557 13.4077 2.76841C13.355 3.23908 13.6946 3.66479 14.1646 3.71776C17.4063 4.07951 19.9259 6.60477 20.2904 9.85654C20.3392 10.2922 20.7047 10.621 21.1409 10.621C21.1738 10.621 21.2057 10.619 21.2385 10.615C21.4666 10.59 21.6697 10.4771 21.8132 10.2972C21.9556 10.1174 22.0203 9.89351 21.9944 9.66467C21.5403 5.60746 18.4002 2.45862 14.3558 2.00793ZM14.4181 5.48994C13.942 5.402 13.5048 5.70579 13.4142 6.17047C13.3236 6.63515 13.6283 7.08884 14.0914 7.17978C15.4857 7.45159 16.5623 8.53085 16.8351 9.92989V9.93089C16.9128 10.3336 17.2674 10.6264 17.6757 10.6264C17.7305 10.6264 17.7852 10.6214 17.841 10.6114C18.3041 10.5185 18.6088 10.0658 18.5182 9.60012C18.1109 7.51055 16.5025 5.89666 14.4181 5.48994ZM16.0001 15.0905C16.4515 14.8302 16.9615 14.5362 17.6047 14.673C18.1873 14.7959 20.1731 16.4078 20.7169 16.9665C21.0734 17.3322 21.2716 17.7099 21.3025 18.0877C21.3562 19.5697 19.3425 21.2615 18.976 21.4724C18.489 21.8231 17.9224 22 17.286 22C16.6357 22 15.9106 21.8151 15.1199 21.4464C10.8296 19.6556 4.27553 13.231 2.54266 8.97395C1.82362 7.38903 1.81864 6.08193 2.53071 5.09961C2.81454 4.63892 4.43288 2.71325 5.88192 2.7742C6.26733 2.80718 6.64179 3.00505 7.00928 3.3648C7.56499 3.90942 9.13653 5.90106 9.25803 6.48565C9.39247 7.13521 9.09769 7.65185 8.83576 8.10554C8.7799 8.20307 8.70463 8.31881 8.62217 8.4456C8.3092 8.92683 7.89264 9.56735 8.04004 9.97626C9.09669 12.5705 11.5376 14.8339 14.133 15.8972C14.5343 16.0431 15.1745 15.6235 15.6542 15.3092C15.7791 15.2273 15.8932 15.1525 15.9893 15.0967L16.0001 15.0905Z"
                        fill="#242424"
                      />
                    </svg>
                    <span>+82 53.811.6666</span>
                  </p>
                  <p>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="mr-5 inline-block"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M16.9395 3C18.2805 3 19.5705 3.53 20.5195 4.481C21.4695 5.43 22.0005 6.71 22.0005 8.05V15.95C22.0005 18.74 19.7305 21 16.9395 21H7.06049C4.26949 21 2.00049 18.74 2.00049 15.95V8.05C2.00049 5.26 4.25949 3 7.06049 3H16.9395ZM18.5305 9.54L18.6105 9.46C18.8495 9.17 18.8495 8.75 18.5995 8.46C18.4605 8.311 18.2695 8.22 18.0705 8.2C17.8605 8.189 17.6605 8.26 17.5095 8.4L13.0005 12C12.4205 12.481 11.5895 12.481 11.0005 12L6.50049 8.4C6.18949 8.17 5.75949 8.2 5.50049 8.47C5.23049 8.74 5.20049 9.17 5.42949 9.47L5.56049 9.6L10.1105 13.15C10.6705 13.59 11.3495 13.83 12.0605 13.83C12.7695 13.83 13.4605 13.59 14.0195 13.15L18.5305 9.54Z"
                        fill="#242424"
                      />
                    </svg>

                    <span>E.homepsh@hanmail.net</span>
                  </p>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide
            className={`flex flex-wrap content-center justify-center`}
          >
            <div className="max-w-screen-lg w-full">
              <div className="sectionHeader pb-8 md:pb-16 flex flex-wrap flex-col base:flex-row base:justify-between">
                <div className="numbering">
                  <img
                    src={nubmer}
                    alt="4 contact us"
                    className="mx-auto base:m-0 h-36 base:h-auto"
                  />
                </div>
                <div className="headText flex flex-col flex-wrap justify-center text-center base:text-right">
                  <h2 className="text-3xl lg:text-5xl leading-normal font-bold">
                    Daegu·Gyeongbuk Branch
                  </h2>
                </div>
              </div>
              <div className="sectionBody grid grid-cols-1 sm:grid-cols-2 base:gap-x-5 sm:gap-x-4 gap-x-2">
                <div className="max-w-sm md:max-w-none w-full mx-auto">
                  <div className="brachMapRatio relative">
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1612.073099704244!2d128.242084!3d36.089924!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x8f6fbbd05893d0fa!2zKOyjvCnri4jrgpjrhbjsu7Ttjbzri4g!5e0!3m2!1sko!2skr!4v1639984878233!5m2!1sko!2skr"
                      allowFullScreen
                      loading="lazy"
                      className="w-full h-full absolute left-0 top-0"
                    ></iframe>
                  </div>
                </div>
                <div className="text-sm sm:text-lg lg:text-2xl space-y-4 relative">
                  <p className="absolute sm:static right-5 bottom-8">
                    <img
                      src={ninano}
                      alt="ninano company Ltd."
                      className="w-20 sm:w-1/3 base:w-auto"
                    />
                  </p>
                  <p className="keep-all">
                    Yeongnam-daero 2905, Gimcheon-si, Gyeongsangbuk-do (Ninano
                    Company)
                  </p>
                  <p>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="mr-5 inline-block"
                    >
                      <path
                        d="M18.1668 14.3797C17.4951 14.2352 16.9637 14.5471 16.4932 14.8194C16.0114 15.1001 15.0951 15.8434 14.57 15.6531C11.8811 14.546 9.35213 12.1925 8.25739 9.49286C8.06444 8.95647 8.80424 8.0344 9.08283 7.54686C9.35316 7.07492 9.65858 6.53852 9.51928 5.86178C9.3934 5.25366 7.76522 3.18188 7.18947 2.61533C6.80977 2.2411 6.42078 2.03527 6.02147 2.00201C4.5202 1.93756 2.84352 3.94073 2.54945 4.41996C1.81274 5.44181 1.81687 6.80152 2.56183 8.45021C4.35717 12.8786 11.1475 19.5618 15.5925 21.4246C16.4128 21.8082 17.1629 22.0005 17.8366 22.0005C18.496 22.0005 19.0831 21.8165 19.5876 21.4516C19.9684 21.2323 22.0536 19.4724 21.9989 17.9307C21.9659 17.5378 21.7606 17.1448 21.3912 16.7644C20.8289 16.1833 18.7704 14.5065 18.1668 14.3797Z"
                        fill="#242424"
                      />
                    </svg>
                    <span>+82 10.3909.8681</span>
                  </p>
                  <p>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="mr-5 inline-block"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M14.3558 2.00793C14.1328 1.97595 13.9087 2.04191 13.7304 2.18381C13.5472 2.32771 13.4326 2.53557 13.4077 2.76841C13.355 3.23908 13.6946 3.66479 14.1646 3.71776C17.4063 4.07951 19.9259 6.60477 20.2904 9.85654C20.3392 10.2922 20.7047 10.621 21.1409 10.621C21.1738 10.621 21.2057 10.619 21.2385 10.615C21.4666 10.59 21.6697 10.4771 21.8132 10.2972C21.9556 10.1174 22.0203 9.89351 21.9944 9.66467C21.5403 5.60746 18.4002 2.45862 14.3558 2.00793ZM14.4181 5.48994C13.942 5.402 13.5048 5.70579 13.4142 6.17047C13.3236 6.63515 13.6283 7.08884 14.0914 7.17978C15.4857 7.45159 16.5623 8.53085 16.8351 9.92989V9.93089C16.9128 10.3336 17.2674 10.6264 17.6757 10.6264C17.7305 10.6264 17.7852 10.6214 17.841 10.6114C18.3041 10.5185 18.6088 10.0658 18.5182 9.60012C18.1109 7.51055 16.5025 5.89666 14.4181 5.48994ZM16.0001 15.0905C16.4515 14.8302 16.9615 14.5362 17.6047 14.673C18.1873 14.7959 20.1731 16.4078 20.7169 16.9665C21.0734 17.3322 21.2716 17.7099 21.3025 18.0877C21.3562 19.5697 19.3425 21.2615 18.976 21.4724C18.489 21.8231 17.9224 22 17.286 22C16.6357 22 15.9106 21.8151 15.1199 21.4464C10.8296 19.6556 4.27553 13.231 2.54266 8.97395C1.82362 7.38903 1.81864 6.08193 2.53071 5.09961C2.81454 4.63892 4.43288 2.71325 5.88192 2.7742C6.26733 2.80718 6.64179 3.00505 7.00928 3.3648C7.56499 3.90942 9.13653 5.90106 9.25803 6.48565C9.39247 7.13521 9.09769 7.65185 8.83576 8.10554C8.7799 8.20307 8.70463 8.31881 8.62217 8.4456C8.3092 8.92683 7.89264 9.56735 8.04004 9.97626C9.09669 12.5705 11.5376 14.8339 14.133 15.8972C14.5343 16.0431 15.1745 15.6235 15.6542 15.3092C15.7791 15.2273 15.8932 15.1525 15.9893 15.0967L16.0001 15.0905Z"
                        fill="#242424"
                      />
                    </svg>
                    <span>+82 70.4759.3381</span>
                  </p>
                  <p>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="mr-5 inline-block"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M16.9395 3C18.2805 3 19.5705 3.53 20.5195 4.481C21.4695 5.43 22.0005 6.71 22.0005 8.05V15.95C22.0005 18.74 19.7305 21 16.9395 21H7.06049C4.26949 21 2.00049 18.74 2.00049 15.95V8.05C2.00049 5.26 4.25949 3 7.06049 3H16.9395ZM18.5305 9.54L18.6105 9.46C18.8495 9.17 18.8495 8.75 18.5995 8.46C18.4605 8.311 18.2695 8.22 18.0705 8.2C17.8605 8.189 17.6605 8.26 17.5095 8.4L13.0005 12C12.4205 12.481 11.5895 12.481 11.0005 12L6.50049 8.4C6.18949 8.17 5.75949 8.2 5.50049 8.47C5.23049 8.74 5.20049 9.17 5.42949 9.47L5.56049 9.6L10.1105 13.15C10.6705 13.59 11.3495 13.83 12.0605 13.83C12.7695 13.83 13.4605 13.59 14.0195 13.15L18.5305 9.54Z"
                        fill="#242424"
                      />
                    </svg>

                    <span>sunji@ninanobiz.com</span>
                  </p>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide
            className={`flex flex-wrap content-center justify-center`}
          >
            <div className="max-w-screen-lg w-full">
              <div className="sectionHeader pb-8 md:pb-16 flex flex-wrap flex-col base:flex-row base:justify-between">
                <div className="numbering">
                  <img
                    src={nubmer}
                    alt="4 contact us"
                    className="mx-auto base:m-0 h-36 base:h-auto"
                  />
                </div>
                <div className="headText flex flex-col flex-wrap justify-center text-center base:text-right">
                  <h2 className="text-3xl lg:text-5xl leading-normal font-bold">
                    Gwangju·Jeolla Branch
                  </h2>
                </div>
              </div>
              <div className="sectionBody grid grid-cols-1 sm:grid-cols-2 base:gap-x-5 sm:gap-x-4 gap-x-2">
                <div className="max-w-sm md:max-w-none w-full mx-auto">
                  <div className="brachMapRatio relative">
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d818.891138143312!2d126.3987323292416!3d34.816895599796695!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3573bafb4feefdad%3A0xb28c5df9d4bc0afc!2z7KCE652864Ko64-EIOuqqe2PrOyLnCDsmqnri7nroZwgMzM5!5e0!3m2!1sko!2skr!4v1639985373297!5m2!1sko!2skr"
                      allowFullScreen
                      loading="lazy"
                      className="w-full h-full absolute left-0 top-0"
                    ></iframe>
                  </div>
                </div>
                <div className="text-sm sm:text-lg lg:text-2xl space-y-4 relative">
                  <p className="keep-all pt-5 sm:pt-20">
                    4th Floor, 339, Yongdang-ro, Mokpo-si, Jeollanam-do (Hansang
                    Company Inc)
                  </p>
                  <p>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="mr-5 inline-block"
                    >
                      <path
                        d="M18.1668 14.3797C17.4951 14.2352 16.9637 14.5471 16.4932 14.8194C16.0114 15.1001 15.0951 15.8434 14.57 15.6531C11.8811 14.546 9.35213 12.1925 8.25739 9.49286C8.06444 8.95647 8.80424 8.0344 9.08283 7.54686C9.35316 7.07492 9.65858 6.53852 9.51928 5.86178C9.3934 5.25366 7.76522 3.18188 7.18947 2.61533C6.80977 2.2411 6.42078 2.03527 6.02147 2.00201C4.5202 1.93756 2.84352 3.94073 2.54945 4.41996C1.81274 5.44181 1.81687 6.80152 2.56183 8.45021C4.35717 12.8786 11.1475 19.5618 15.5925 21.4246C16.4128 21.8082 17.1629 22.0005 17.8366 22.0005C18.496 22.0005 19.0831 21.8165 19.5876 21.4516C19.9684 21.2323 22.0536 19.4724 21.9989 17.9307C21.9659 17.5378 21.7606 17.1448 21.3912 16.7644C20.8289 16.1833 18.7704 14.5065 18.1668 14.3797Z"
                        fill="#242424"
                      />
                    </svg>
                    <span>+82 10.6542.6633</span>
                  </p>
                  <p>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="mr-5 inline-block"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M14.3558 2.00793C14.1328 1.97595 13.9087 2.04191 13.7304 2.18381C13.5472 2.32771 13.4326 2.53557 13.4077 2.76841C13.355 3.23908 13.6946 3.66479 14.1646 3.71776C17.4063 4.07951 19.9259 6.60477 20.2904 9.85654C20.3392 10.2922 20.7047 10.621 21.1409 10.621C21.1738 10.621 21.2057 10.619 21.2385 10.615C21.4666 10.59 21.6697 10.4771 21.8132 10.2972C21.9556 10.1174 22.0203 9.89351 21.9944 9.66467C21.5403 5.60746 18.4002 2.45862 14.3558 2.00793ZM14.4181 5.48994C13.942 5.402 13.5048 5.70579 13.4142 6.17047C13.3236 6.63515 13.6283 7.08884 14.0914 7.17978C15.4857 7.45159 16.5623 8.53085 16.8351 9.92989V9.93089C16.9128 10.3336 17.2674 10.6264 17.6757 10.6264C17.7305 10.6264 17.7852 10.6214 17.841 10.6114C18.3041 10.5185 18.6088 10.0658 18.5182 9.60012C18.1109 7.51055 16.5025 5.89666 14.4181 5.48994ZM16.0001 15.0905C16.4515 14.8302 16.9615 14.5362 17.6047 14.673C18.1873 14.7959 20.1731 16.4078 20.7169 16.9665C21.0734 17.3322 21.2716 17.7099 21.3025 18.0877C21.3562 19.5697 19.3425 21.2615 18.976 21.4724C18.489 21.8231 17.9224 22 17.286 22C16.6357 22 15.9106 21.8151 15.1199 21.4464C10.8296 19.6556 4.27553 13.231 2.54266 8.97395C1.82362 7.38903 1.81864 6.08193 2.53071 5.09961C2.81454 4.63892 4.43288 2.71325 5.88192 2.7742C6.26733 2.80718 6.64179 3.00505 7.00928 3.3648C7.56499 3.90942 9.13653 5.90106 9.25803 6.48565C9.39247 7.13521 9.09769 7.65185 8.83576 8.10554C8.7799 8.20307 8.70463 8.31881 8.62217 8.4456C8.3092 8.92683 7.89264 9.56735 8.04004 9.97626C9.09669 12.5705 11.5376 14.8339 14.133 15.8972C14.5343 16.0431 15.1745 15.6235 15.6542 15.3092C15.7791 15.2273 15.8932 15.1525 15.9893 15.0967L16.0001 15.0905Z"
                        fill="#242424"
                      />
                    </svg>
                    <span>+82 61.277.9988</span>
                  </p>
                  <p>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="mr-5 inline-block"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M16.9395 3C18.2805 3 19.5705 3.53 20.5195 4.481C21.4695 5.43 22.0005 6.71 22.0005 8.05V15.95C22.0005 18.74 19.7305 21 16.9395 21H7.06049C4.26949 21 2.00049 18.74 2.00049 15.95V8.05C2.00049 5.26 4.25949 3 7.06049 3H16.9395ZM18.5305 9.54L18.6105 9.46C18.8495 9.17 18.8495 8.75 18.5995 8.46C18.4605 8.311 18.2695 8.22 18.0705 8.2C17.8605 8.189 17.6605 8.26 17.5095 8.4L13.0005 12C12.4205 12.481 11.5895 12.481 11.0005 12L6.50049 8.4C6.18949 8.17 5.75949 8.2 5.50049 8.47C5.23049 8.74 5.20049 9.17 5.42949 9.47L5.56049 9.6L10.1105 13.15C10.6705 13.59 11.3495 13.83 12.0605 13.83C12.7695 13.83 13.4605 13.59 14.0195 13.15L18.5305 9.54Z"
                        fill="#242424"
                      />
                    </svg>

                    <span>xhqkfdl@naver.com</span>
                  </p>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
};
