import { SUBHEADER_EN } from "../../../layout/eng/subheader";
import "../../page.css";
import large from "../../../assets/images/products/large_en.jpg";
import { FOOTER_EN } from "../../../layout/eng/footer";
export const LARGE_EN = () => {
  return (
    <main id="content">
      <SUBHEADER_EN />
      <div className="w-full">
        <div className="max-w-screen-lg mx-auto py-20 lg:px-0 px-5">
          <img
            src={large}
            alt="나의 가족을 지키는 평생지키미. 코로나바이러스 박멸! 인플루엔자A박멸! 8가지 유해세균 박멸! 바이러스 및 세균 그리고 지든때 끝! 샌퓨리 바이캅세정살균티슈 - 대용량 캐니스터. 라벤더 향기로 깔끔한 마무리! 닦고 나면 손에 꼬릿한 냄새가 나는 소독티슈와 달리 바이캅은 세정 후 개운하고 향긋하게 남아 기분까지 상쾌합니다. 최고로 만들기 위한 8가지 특징 . 코로나바이러스 그리고 인플루엔자A 99.99% 제거 코로나바이러스 및 인플루엔자A 실험 테스트 합격하였으며 3분  이내 제거로 안전하고 빠르게 효과를 불 수 있습니다. 피부 저자극 . 6가지 유해성분 무첨가 및 약산성의 산도를 띄어 피부자극을 최소화 했습니다. CE인증 중 유럽의 EU 통합인증으로 제품의 품질과 신뢰를 인정받았습니다. 락스 무첨가. 락스 무첨가로 락스 특유의 강한 향이 나지 않아 상쾌합니다. 유해세균 99.9% 제거. 대장균, 녹농균, 황생포도구균, 바실러스 , 세레우스균, 고초균, 살모넬라균, 페렴간균, 포도상구균 8개 시험 합격. FDA 등록 안전 제조 시설. FDA 등록 안전 제조 시설로 제품의 신뢰와 효과가 뛰어납니다. 기름때와 찌든때 강력제거. 살균성분을 포함한 원단의 강한 흡착력으로 오래된 찌든때, 기름때를 한 장으로 말끔히 제거합니다. 멜트블로운 공법 원단. 멜트블로운 공법 원단을 사용하여 흡착력을 업그레이드 하였습니다. 찌든때와 기름때 제거에 탁월합니다. 중독되는 청소! 한 번 닦기 시작하면 없어서는 안될 필수 템! 바이캅이 지켜드리겠습니다. 안전확인대상 생활화학제품 표시사항. 자세한 표기내용은 홈페이지에 기재된 곳으로 연락주세요 "
          />
        </div>
      </div>
      <FOOTER_EN />
    </main>
  );
};
