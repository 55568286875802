import "../../css/home.css";
import main from "../../../assets/images/home/main_back3.jpg";
import test from "../../../assets/images/home/light.png";
import logo from "../../../assets/icon/main_logo.svg";

import "react-circular-progressbar/dist/styles.css";
export const Main = () => {
  const mainback = {
    backgroundImage: "url(" + main + ")",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  };
  return (
    <div id="main" className="w-full h-screen">
      <div className="w-full h-full relative pt-10 md:pt-14" style={mainback}>
        <div className="light relative mx-auto">
          <img src={test} alt="dwa" className="mix-blend-hard-light w-full" />

          <svg
            version="1.1"
            id="레이어_1"
            xmlns="http://www.w3.org/2000/svg"
            width="40%"
            height="40%"
            viewBox="0 0 152 168"
            className="absolute left-1/2 top-1/4 -translate-x-1/2  transform"
          >
            <switch>
              <g>
                <g>
                  <path
                    d="M47.12,96.46v5.33h8.52v2.83c0,0.53-0.13,0.92-0.4,1.15c-0.27,0.24-0.66,0.36-1.2,0.36h-6.93v5.55h9.98v2.39
				c0,0.62-0.17,1.09-0.49,1.42c-0.33,0.32-0.81,0.48-1.43,0.48H43.47c-0.62,0-1.1-0.16-1.43-0.48c-0.33-0.32-0.5-0.79-0.5-1.42
				V92.17H57.1v2.78c0,0.53-0.13,0.92-0.4,1.15c-0.27,0.24-0.66,0.36-1.2,0.36H47.12z"
                  />
                  <path
                    d="M67.71,115.93c-0.05-0.2-0.1-0.31-0.14-0.33h-0.03v-0.05l-0.91,0.27c-0.59,0.18-1.32,0.28-2.2,0.28
				c-2.11,0-3.66-0.52-4.65-1.57c-0.99-1.04-1.48-2.35-1.48-3.93c0-1.45,0.5-2.7,1.51-3.75s2.57-1.59,4.67-1.61l2.91-0.05v-0.47
				c0-0.82-0.16-1.47-0.48-1.94c-0.32-0.47-0.73-0.7-1.22-0.7c-0.4,0-0.78,0.13-1.14,0.4s-0.6,0.75-0.73,1.44h-5.28
				c0.05-1.74,0.79-3.12,2.2-4.14c1.41-1.02,2.98-1.53,4.7-1.53c2.38,0,4.22,0.6,5.53,1.81c1.3,1.21,1.95,2.67,1.95,4.37v8.36
				c0,0.51,0.02,1.03,0.07,1.55s0.11,1.05,0.21,1.58H67.71z M67.41,108.42l-2.31,0.03c-0.33,0-0.65,0.19-0.95,0.56
				c-0.3,0.38-0.45,0.87-0.45,1.47c0,0.62,0.16,1.1,0.47,1.43c0.31,0.33,0.64,0.49,0.99,0.49c0.7,0,1.25-0.23,1.65-0.7
				c0.4-0.47,0.6-1.22,0.6-2.27V108.42z"
                  />
                  <path
                    d="M75.6,115.98v-14.13c0-0.57-0.01-1.14-0.04-1.71c-0.03-0.57-0.08-1.12-0.15-1.65h5.25c0.04,0.2,0.09,0.43,0.15,0.67
				s0.08,0.44,0.04,0.59c0.37-0.48,0.87-0.84,1.5-1.09c0.63-0.25,1.23-0.37,1.8-0.37c0.71,0,1.42,0.12,2.12,0.37
				c0.7,0.25,1.3,0.75,1.81,1.5l-1.73,4.23c-0.4-0.75-0.87-1.26-1.4-1.54c-0.53-0.27-1.01-0.41-1.43-0.41
				c-0.71,0-1.29,0.38-1.72,1.13c-0.43,0.75-0.65,1.65-0.65,2.69v7.89c0,0.57-0.2,1.01-0.6,1.33c-0.4,0.32-0.9,0.48-1.48,0.48H75.6z
				"
                  />
                  <path
                    d="M89.76,99.1v-5.14h5.53v5.14h2.8v1.98c0,0.53-0.13,0.92-0.4,1.16s-0.66,0.36-1.2,0.36h-1.21v8.14
				c0,0.48,0.08,0.84,0.25,1.09c0.17,0.25,0.39,0.37,0.69,0.37h1.87v2.31c0,0.53-0.13,0.92-0.4,1.15s-0.66,0.36-1.2,0.36h-1.38
				c-1.72,0-3.05-0.47-3.97-1.4c-0.93-0.93-1.39-2.14-1.39-3.63v-8.38h-0.11c-0.53,0-0.93-0.12-1.2-0.36
				c-0.27-0.24-0.4-0.62-0.4-1.16V99.1H89.76z"
                  />
                  <path
                    d="M100.04,116.01v-23.7h5.55v7.45c0.28-0.33,0.69-0.62,1.25-0.88c0.56-0.26,1.15-0.38,1.77-0.38c1.7,0,3.13,0.55,4.29,1.66
				c1.15,1.11,1.73,2.66,1.73,4.66v9.26c0,0.57-0.22,1.03-0.66,1.39s-0.95,0.54-1.54,0.54h-3.33v-10.53c0-0.88-0.18-1.59-0.54-2.14
				s-0.76-0.83-1.2-0.83c-0.44,0-0.85,0.28-1.22,0.83s-0.56,1.26-0.56,2.14v8.6c0,0.57-0.22,1.03-0.66,1.39
				c-0.44,0.36-0.95,0.54-1.54,0.54H100.04z"
                  />
                  <path
                    d="M57.88,133.9c-0.25,0.21-0.54,0.31-0.88,0.31h-1.93v-13.62h3.19v5.93l3.65-5.93h3.58l-4.2,6.38l4.51,7.23h-3.49
				l-4.06-6.71v5.61C58.26,133.43,58.14,133.7,57.88,133.9z"
                  />
                  <path
                    d="M74.5,129.16c0,1.76-0.33,3.06-1,3.88c-0.67,0.83-1.76,1.24-3.28,1.24c-1.45,0-2.52-0.42-3.22-1.25s-1.05-2.12-1.05-3.88
				c0-1.75,0.38-3.04,1.14-3.86c0.76-0.82,1.8-1.23,3.14-1.23c1.34,0,2.39,0.41,3.14,1.23C74.12,126.12,74.5,127.41,74.5,129.16z
				 M71.34,129.16c0-1-0.11-1.71-0.33-2.14c-0.22-0.43-0.48-0.64-0.79-0.64c-0.33,0-0.59,0.21-0.8,0.64
				c-0.21,0.43-0.31,1.14-0.31,2.14c0,0.89,0.1,1.58,0.3,2.08c0.2,0.49,0.47,0.74,0.82,0.74c0.37,0,0.64-0.25,0.83-0.74
				C71.25,130.74,71.34,130.05,71.34,129.16z"
                  />
                  <path
                    d="M75.73,134.21v-8.08c0-0.32-0.01-0.65-0.02-0.97c-0.02-0.32-0.04-0.64-0.09-0.94h3c0.02,0.12,0.05,0.24,0.09,0.39
				c0.04,0.14,0.04,0.25,0.02,0.34c0.21-0.27,0.5-0.48,0.86-0.62c0.36-0.14,0.71-0.21,1.03-0.21c0.41,0,0.81,0.07,1.21,0.21
				s0.74,0.43,1.04,0.86l-0.99,2.42c-0.23-0.43-0.5-0.72-0.8-0.88c-0.3-0.16-0.58-0.24-0.82-0.24c-0.41,0-0.74,0.22-0.98,0.64
				c-0.25,0.43-0.37,0.94-0.37,1.54v4.51c0,0.32-0.12,0.58-0.35,0.76c-0.23,0.18-0.51,0.28-0.85,0.28H75.73z"
                  />
                  <path
                    d="M86.29,129.96c0,0.64,0.12,1.16,0.35,1.55c0.24,0.39,0.58,0.59,1.03,0.59c0.13,0,0.25-0.02,0.38-0.05
				c0.13-0.03,0.24-0.09,0.35-0.17s0.19-0.19,0.27-0.32c0.07-0.13,0.12-0.3,0.14-0.51h3.14c-0.04,0.5-0.19,0.95-0.43,1.35
				s-0.56,0.74-0.95,1.02s-0.83,0.5-1.33,0.64c-0.5,0.15-1.01,0.22-1.55,0.22c-0.75,0-1.42-0.13-1.98-0.39
				c-0.57-0.26-1.04-0.61-1.42-1.07c-0.38-0.46-0.66-1-0.85-1.62c-0.19-0.62-0.28-1.31-0.28-2.05c0-0.79,0.1-1.49,0.31-2.12
				s0.5-1.16,0.88-1.6c0.38-0.44,0.84-0.78,1.38-1.01c0.54-0.24,1.15-0.35,1.82-0.35c0.63,0,1.21,0.11,1.75,0.33
				c0.53,0.22,1,0.54,1.39,0.95c0.39,0.41,0.7,0.91,0.92,1.49c0.22,0.58,0.33,1.23,0.33,1.96v1.16H86.29z M88.81,128.17
				c0.01-0.2,0-0.41-0.04-0.64c-0.04-0.23-0.1-0.44-0.2-0.64c-0.09-0.2-0.23-0.36-0.39-0.5c-0.17-0.13-0.38-0.2-0.63-0.2
				c-0.23,0-0.42,0.06-0.58,0.17s-0.29,0.27-0.38,0.46s-0.17,0.4-0.22,0.64c-0.05,0.24-0.07,0.47-0.07,0.7H88.81z"
                  />
                  <path
                    d="M97.85,134.18c-0.03-0.12-0.06-0.18-0.08-0.19h-0.02v-0.03l-0.52,0.16c-0.34,0.1-0.75,0.16-1.26,0.16
				c-1.21,0-2.09-0.3-2.66-0.9c-0.57-0.6-0.85-1.35-0.85-2.25c0-0.83,0.29-1.54,0.87-2.15c0.58-0.6,1.47-0.91,2.67-0.92l1.67-0.03
				v-0.27c0-0.47-0.09-0.84-0.28-1.11c-0.18-0.27-0.42-0.4-0.7-0.4c-0.23,0-0.45,0.08-0.65,0.23c-0.21,0.15-0.34,0.43-0.42,0.83
				h-3.02c0.03-1,0.45-1.79,1.26-2.37c0.81-0.58,1.7-0.87,2.69-0.87c1.36,0,2.42,0.35,3.16,1.04c0.74,0.69,1.12,1.52,1.12,2.5v4.78
				c0,0.29,0.01,0.59,0.04,0.89s0.07,0.6,0.12,0.9H97.85z M97.68,129.88l-1.32,0.02c-0.19,0-0.37,0.11-0.54,0.32
				s-0.26,0.5-0.26,0.84c0,0.36,0.09,0.63,0.27,0.82c0.18,0.19,0.37,0.28,0.57,0.28c0.4,0,0.71-0.13,0.94-0.4
				c0.23-0.27,0.35-0.7,0.35-1.3V129.88z"
                  />
                </g>
                <g>
                  <path
                    d="M31.73,124.59l-0.07,0.05l-0.27-0.47l0.07-0.06c0.71-0.62,0.96-1.48,0.42-2.41c-0.63-1.08-2.02-1.36-3.45-0.52
				c-1.46,0.85-1.9,2.14-1.28,3.21c0.57,0.97,1.45,1.15,2.35,0.84l0.08-0.03l0.27,0.47l-0.08,0.04c-1.04,0.42-2.25,0.21-3.03-1.13
				c-0.84-1.44-0.25-2.98,1.35-3.91c1.61-0.94,3.33-0.6,4.17,0.84C32.99,122.77,32.64,123.88,31.73,124.59z"
                  />
                  <path
                    d="M33.24,129.86c-1.1,0.8-2.44,0.8-3.31-0.39s-0.45-2.46,0.65-3.27c1.11-0.81,2.44-0.81,3.31,0.38
				C34.77,127.79,34.35,129.06,33.24,129.86z M32.89,129.38c1.02-0.75,1.22-1.75,0.65-2.53s-1.58-0.9-2.61-0.16
				c-1.02,0.75-1.22,1.75-0.65,2.53S31.87,130.12,32.89,129.38z"
                  />
                  <path
                    d="M38.31,134.94l-2.02,1.85l-0.37-0.4l1.84-1.69c0.28-0.26,0.45-0.42,0.61-0.6c0.41-0.48,0.44-0.98,0.03-1.43
				c-0.45-0.49-1.17-0.51-2.19,0.42l-1.8,1.64l-0.37-0.4l1.84-1.69c0.28-0.26,0.45-0.42,0.61-0.6c0.41-0.48,0.44-0.98,0.03-1.43
				c-0.45-0.49-1.17-0.51-2.19,0.42l-1.8,1.64l-0.37-0.4l3.43-3.14l0.37,0.4l-0.59,0.54l0.56-0.16c0.39,0.01,0.73,0.19,1.02,0.52
				c0.5,0.54,0.52,1.15,0.18,1.67l0.68-0.13c0.39,0,0.73,0.19,1.02,0.52c0.56,0.62,0.5,1.3,0.03,1.88
				C38.71,134.56,38.59,134.68,38.31,134.94z"
                  />
                  <path
                    d="M42.29,138.88c-1.11,1.27-2.43,1.21-3.32,0.44c-0.35-0.3-0.59-0.7-0.63-1.1l0.11-0.57l-1.65,1.9l-0.41-0.36l4.2-4.83
				l0.41,0.36l-0.58,0.67l0.57-0.18c0.45-0.03,0.9,0.16,1.31,0.51C43.27,136.56,43.19,137.84,42.29,138.88z M41.79,138.54
				c0.73-0.84,0.92-1.88,0.15-2.55c-0.62-0.54-1.49-0.64-2.41,0.42l-0.23,0.26c-0.87,1-0.56,1.87-0.04,2.32
				C39.96,139.6,40.97,139.49,41.79,138.54z"
                  />
                  <path
                    d="M44.38,142.32l-0.47,0.26c-0.46,0.07-0.91-0.08-1.33-0.38c-0.7-0.51-0.93-1.23-0.43-1.92c0.63-0.86,1.75-0.68,3.11,0.3
				l0.24,0.18l0.1-0.14c0.12-0.17,0.23-0.31,0.32-0.47c0.28-0.46,0.2-0.9-0.34-1.29c-0.52-0.38-1.09-0.37-1.57,0.17l-0.46-0.34
				c0.59-0.7,1.5-0.77,2.29-0.19c0.83,0.61,0.95,1.27,0.56,1.93c-0.1,0.17-0.22,0.33-0.35,0.51l-0.96,1.32
				c-0.24,0.33-0.42,0.62-0.69,1.1l-0.44-0.32L44.38,142.32z M44.93,141.55l0.32-0.43c-1.01-0.84-2.06-1.23-2.59-0.5
				c-0.35,0.48-0.23,0.95,0.31,1.35C43.62,142.44,44.42,142.25,44.93,141.55z"
                  />
                  <path
                    d="M50.23,143.94l-1.34,2.23l-0.47-0.28l1.31-2.18c0.14-0.23,0.24-0.4,0.35-0.61c0.27-0.57,0.2-1.14-0.47-1.54
				c-0.66-0.4-1.53-0.27-2.28,0.98l-1.19,1.98l-0.47-0.28l2.4-3.99l0.47,0.28l-0.46,0.77l0.47-0.3c0.52-0.13,0.95-0.04,1.39,0.23
				c0.84,0.51,1.05,1.26,0.69,2.03C50.52,143.47,50.43,143.61,50.23,143.94z"
                  />
                  <path
                    d="M51.73,147.9c-0.97,0.89-1.54,1.03-2.13,0.74c-0.14-0.07-0.23-0.11-0.31-0.18l0.2-0.4c0.1,0.07,0.17,0.11,0.26,0.15
				c0.38,0.19,0.76,0.13,1.36-0.41l0.2-0.18l0.49-5.14l0.55,0.27l-0.53,4.48l3.22-3.15l0.54,0.26L51.73,147.9z"
                  />
                  <path
                    d="M60.98,148.79c-0.56,1.59-1.81,2.02-2.92,1.62c-0.43-0.15-0.81-0.43-0.99-0.79l-0.11-0.57l-0.84,2.37l-0.51-0.18
				l2.13-6.03l0.51,0.18l-0.3,0.84l0.46-0.38c0.41-0.19,0.9-0.19,1.4-0.01C61.05,146.28,61.44,147.49,60.98,148.79z M60.39,148.65
				c0.37-1.05,0.17-2.08-0.79-2.42c-0.78-0.28-1.62-0.05-2.09,1.27l-0.12,0.33c-0.44,1.25,0.16,1.94,0.81,2.17
				C59.08,150.31,59.97,149.84,60.39,148.65z"
                  />
                  <path
                    d="M65.54,150.2l-3.42-0.88c-0.28,1.35,0.27,1.99,1.01,2.17c0.63,0.16,1.29-0.08,1.64-0.78l0.55,0.14
				c-0.48,0.99-1.44,1.28-2.34,1.05c-1.13-0.29-1.8-1.3-1.4-2.88c0.37-1.45,1.51-2.15,2.7-1.84c1.26,0.32,1.66,1.5,1.34,2.74
				C65.61,149.98,65.59,150.07,65.54,150.2z M62.22,148.91l2.9,0.74c0.24-1.18-0.21-1.85-0.97-2.05
				C63.42,147.41,62.59,147.8,62.22,148.91z"
                  />
                  <path
                    d="M70.75,150.93c-0.22,1.35-1.16,2.29-2.62,2.05c-1.46-0.24-2.06-1.43-1.84-2.77c0.22-1.35,1.17-2.3,2.62-2.06
				C70.37,148.38,70.97,149.57,70.75,150.93z M70.16,150.83c0.2-1.25-0.36-2.1-1.32-2.25c-0.96-0.15-1.76,0.47-1.96,1.72
				s0.36,2.1,1.32,2.25C69.15,152.71,69.96,152.08,70.16,150.83z"
                  />
                  <path
                    d="M76.01,151.26c-0.11,1.68-1.2,2.43-2.38,2.35c-0.46-0.03-0.89-0.2-1.17-0.49l-0.25-0.52l-0.17,2.51l-0.54-0.04l0.43-6.39
				l0.54,0.04l-0.06,0.89l0.34-0.49c0.35-0.29,0.81-0.42,1.35-0.38C75.4,148.82,76.1,149.88,76.01,151.26z M75.41,151.29
				c0.08-1.11-0.4-2.05-1.42-2.12c-0.83-0.06-1.58,0.39-1.67,1.79l-0.02,0.35c-0.09,1.33,0.68,1.83,1.36,1.87
				C74.59,153.24,75.32,152.55,75.41,151.29z"
                  />
                  <path
                    d="M77.52,152.38c0,0.2,0,0.36,0.01,0.5c0.02,0.3,0.15,0.38,0.54,0.34l0,0.41c-0.14,0.03-0.29,0.03-0.43,0.03
				c-0.36,0-0.63-0.13-0.66-0.63c-0.01-0.16-0.01-0.34-0.01-0.62l0.02-5.26l0.55,0L77.52,152.38z"
                  />
                  <path
                    d="M82.75,151.24l-3.52,0.2c0.14,1.37,0.86,1.81,1.62,1.77c0.65-0.04,1.21-0.47,1.33-1.24l0.57-0.03
				c-0.16,1.09-0.98,1.65-1.91,1.71c-1.16,0.07-2.11-0.69-2.21-2.32c-0.09-1.49,0.79-2.51,2.01-2.58c1.3-0.07,2.04,0.92,2.11,2.21
				C82.75,151.01,82.76,151.1,82.75,151.24z M79.2,151.01l2.99-0.17c-0.13-1.19-0.76-1.7-1.54-1.66
				C79.88,149.23,79.21,149.85,79.2,151.01z"
                  />
                  <path
                    d="M87.19,148.03l0.07,0.43c-0.81,0.07-1.32,0.65-1.13,1.88l0.41,2.58L86,153l-0.73-4.59l0.54-0.08l0.13,0.83l0.24-0.64
				c0.17-0.25,0.44-0.43,0.73-0.47C87.01,148.02,87.1,148.02,87.19,148.03z"
                  />
                  <path
                    d="M92.47,151.72l-0.53,0.12l-0.2-0.88l-0.14,0.56c-0.28,0.43-0.67,0.66-1.16,0.77c-0.93,0.21-1.59-0.2-1.86-0.89
				c-0.08-0.23-0.12-0.39-0.2-0.76l-0.59-2.66l0.53-0.12l0.56,2.53c0.05,0.21,0.11,0.45,0.19,0.67c0.2,0.59,0.69,0.92,1.39,0.76
				c0.76-0.17,1.28-0.86,0.96-2.29l-0.5-2.26l0.53-0.12L92.47,151.72z"
                  />
                  <path
                    d="M96.79,147.63l0.81,2.48l-0.52,0.17l-0.79-2.42c-0.08-0.25-0.14-0.44-0.24-0.66c-0.25-0.58-0.73-0.9-1.47-0.66
				c-0.74,0.24-1.21,0.99-0.75,2.37l0.72,2.2l-0.52,0.17l-1.44-4.42l0.52-0.17l0.28,0.85l0.08-0.55c0.24-0.48,0.59-0.74,1.08-0.9
				c0.94-0.31,1.64,0.03,1.99,0.81C96.62,147.11,96.67,147.27,96.79,147.63z"
                  />
                  <path
                    d="M99.23,143.84l0.23,0.52l0.87-0.38l0.18,0.4l-0.87,0.38l1.68,3.83l-0.5,0.22l-1.68-3.83l-0.46,0.2l-0.18-0.4l0.46-0.2
				l-0.21-0.48c-0.06-0.15-0.14-0.32-0.19-0.48c-0.19-0.62,0.07-1.1,0.59-1.33c0.17-0.07,0.31-0.12,0.47-0.16l0.18,0.42
				c-0.61,0.13-0.85,0.46-0.74,0.86C99.11,143.56,99.18,143.72,99.23,143.84z"
                  />
                  <path
                    d="M105.73,143.8c0.63,1.21,0.44,2.53-0.87,3.21c-1.31,0.68-2.5,0.09-3.14-1.12c-0.64-1.22-0.44-2.54,0.87-3.22
				C103.9,141.99,105.09,142.59,105.73,143.8z M105.2,144.08c-0.59-1.12-1.55-1.46-2.41-1.02c-0.86,0.45-1.13,1.43-0.54,2.56
				c0.59,1.12,1.55,1.46,2.41,1.02C105.51,146.19,105.78,145.2,105.2,144.08z"
                  />
                  <path
                    d="M106.96,140.19l0.23,0.37c-0.72,0.37-0.97,1.12-0.32,2.17l1.37,2.23l-0.46,0.29l-2.45-3.96l0.46-0.29l0.44,0.72
				l-0.03-0.69c0.06-0.3,0.24-0.56,0.5-0.72C106.79,140.25,106.88,140.21,106.96,140.19z"
                  />
                  <path
                    d="M113.74,138.13c1.03,1.34,0.71,2.62-0.23,3.34c-0.36,0.28-0.8,0.44-1.2,0.4l-0.54-0.22l1.54,1.99l-0.43,0.33l-3.91-5.08
				l0.43-0.33l0.54,0.7l-0.07-0.59c0.07-0.45,0.33-0.85,0.76-1.18C111.66,136.71,112.89,137.03,113.74,138.13z M113.3,138.55
				c-0.68-0.89-1.66-1.28-2.47-0.65c-0.66,0.5-0.93,1.34-0.07,2.45l0.21,0.28c0.81,1.05,1.72,0.92,2.26,0.5
				C113.98,140.56,114.07,139.55,113.3,138.55z"
                  />
                  <path
                    d="M117.39,135.07l-2.59,2.4c0.98,0.96,1.82,0.85,2.37,0.33c0.48-0.44,0.63-1.13,0.24-1.8l0.42-0.39
				c0.57,0.94,0.29,1.9-0.39,2.53c-0.85,0.79-2.07,0.81-3.18-0.39c-1.02-1.1-0.98-2.43-0.09-3.27c0.96-0.89,2.16-0.58,3.03,0.36
				C117.25,134.89,117.31,134.95,117.39,135.07z M114.51,137.15l2.19-2.03c-0.86-0.84-1.67-0.83-2.24-0.3
				C113.9,135.34,113.78,136.24,114.51,137.15z"
                  />
                  <path
                    d="M120.85,131.07c1.01,0.92,1.29,2.22,0.3,3.32c-0.99,1.1-2.32,0.95-3.33,0.04c-1.02-0.92-1.3-2.23-0.3-3.33
				C118.5,130.01,119.83,130.15,120.85,131.07z M120.45,131.52c-0.94-0.85-1.96-0.84-2.61-0.12c-0.65,0.72-0.56,1.74,0.38,2.59
				s1.96,0.84,2.61,0.12C121.48,133.38,121.39,132.37,120.45,131.52z"
                  />
                  <path
                    d="M123.99,126.84c1.35,1.01,1.38,2.33,0.67,3.28c-0.27,0.37-0.66,0.64-1.05,0.71l-0.58-0.07l2.01,1.51l-0.33,0.44
				l-5.12-3.84l0.33-0.44l0.71,0.53l-0.22-0.55c-0.06-0.45,0.09-0.91,0.41-1.34C121.61,126.03,122.88,126.01,123.99,126.84z
				 M123.69,127.36c-0.89-0.67-1.94-0.79-2.55,0.03c-0.5,0.66-0.54,1.53,0.59,2.38l0.28,0.21c1.06,0.8,1.9,0.43,2.31-0.12
				C124.88,129.12,124.69,128.12,123.69,127.36z"
                  />
                  <path
                    d="M125.73,126.22c0.17,0.11,0.3,0.2,0.42,0.27c0.26,0.15,0.4,0.08,0.58-0.27l0.34,0.22c-0.05,0.13-0.14,0.26-0.21,0.38
				c-0.2,0.3-0.45,0.46-0.88,0.21c-0.14-0.08-0.29-0.18-0.53-0.33l-4.39-2.88l0.3-0.46L125.73,126.22z"
                  />
                  <path
                    d="M127.63,121.2l-1.75,3.07c1.22,0.63,1.99,0.26,2.36-0.39c0.32-0.57,0.27-1.27-0.32-1.79l0.28-0.5
				c0.83,0.73,0.85,1.72,0.39,2.53c-0.58,1.01-1.73,1.4-3.15,0.58c-1.3-0.74-1.67-2.03-1.06-3.09c0.65-1.13,1.88-1.21,3-0.57
				C127.44,121.07,127.51,121.11,127.63,121.2z M125.5,124.05l1.48-2.6c-1.07-0.54-1.84-0.29-2.23,0.39
				C124.38,122.51,124.53,123.41,125.5,124.05z"
                  />
                </g>
                <g>
                  <g>
                    <g>
                      <path
                        d="M103.31,88.27c-0.32,0.31-0.79,0.47-1.4,0.47H90.65c-0.61,0-1.08-0.16-1.4-0.47s-0.48-0.78-0.48-1.38V65.46h5.46v19.09
						h9.57v2.34C103.79,87.49,103.63,87.96,103.31,88.27z"
                      />
                      <path
                        d="M120.33,88.27c-0.32,0.31-0.79,0.47-1.4,0.47h-11.26c-0.61,0-1.08-0.16-1.4-0.47s-0.48-0.78-0.48-1.38V65.46h5.46
						v19.09h9.57v2.34C120.81,87.49,120.65,87.96,120.33,88.27z"
                      />
                    </g>
                  </g>
                  <path
                    d="M19.82,71.57l3.91,9.95l2.19,5.57c0,0,3.75-3.1,2.51-6.24c-0.3-0.77-0.64-1.63-0.98-2.49l17-14.91l7.09-22.92
				l14.09,47.68l12.46,0l6.98,0c0,0-1.77-5.39-5.7-5.38c-0.97,0-2.04,0-3.12,0l-17.1-50.08c-6.32,1.86-12.16,4.62-17.41,8.1
				l-5.93,16.85L19.82,71.57z"
                  />
                </g>
              </g>
            </switch>
          </svg>

          <div className="font-bold text-lg md:text-xl absolute bottom-10 md:bottom-16 text-center w-full">
            <p className="leading-normal">
              세상을 이롭게 하기 위한 바쁜 발걸음
            </p>
            <p className="leading-normal">오러스코리아의 모토이며 철학입니다</p>
          </div>
        </div>
      </div>
    </div>
  );
};
