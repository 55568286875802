import { FOOTER_EN } from "../../layout/eng/footer";
import { SUBHEADER_EN } from "../../layout/eng/subheader";
import { Branch_EN } from "../../parts/eng/branch/branch";
import "../page.css";
export const MARKET_EN = () => {
  return (
    <main id="content">
      <SUBHEADER_EN />
      <Branch_EN />
      <FOOTER_EN />
    </main>
  );
};
