import React, { useState } from "react";
import "../layout.css";
import Collapse from "@kunukn/react-collapse";

export const MobileMenu_EN = (prop: any) => {
  const main = prop.value.main;
  const [coll, setColl] = useState<boolean>(false);
  const collapseHandler = () => {
    if (coll) {
      setColl(false);
      return;
    }
    setColl(true);
    return;
  };
  return (
    <>
      <div
        onClick={collapseHandler}
        className="cursor-pointer mainTab h-20 flex-wrap flex content-center justify-center hover:bg-gray-200 focus:bg-gray-200 "
      >
        {main}
      </div>
      <Collapse isOpen={coll} transition={`400ms`}>
        {prop.value.sub.map((obj: any) => (
          <div key={`${obj.menus}mobileSub`} className="text-center submenu">
            <a
              href={obj.links}
              className="block hover:text-red-700 py-5 bg-gray-100"
            >
              {obj.menus}
            </a>
          </div>
        ))}
      </Collapse>
    </>
  );
};
