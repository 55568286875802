import React, { useState } from "react";
import nubmer from "../../../assets/images/home/number2_en.png";
import image from "../../../assets/images/home/ci_image.png";
export const Ci_EN = () => {
  return (
    <section>
      <div className="bg-back-gray pb-20 base:pb-0 h-auto base:h-screen overflow-hidden flex flex-wrap content-center w-full pt-20 base:pt-24">
        <div className="lg:max-w-screen-lg lg:m-auto sm:m-6 m-4 base:max-w-none w-full">
          <div className="sectionHeader pb-8 md:pb-16 flex flex-wrap flex-col base:flex-row base:justify-between">
            <div className="numbering">
              <img
                src={nubmer}
                alt="2 CI소개"
                className="mx-auto base:m-0 h-36 base:h-auto"
              />
            </div>
            <div className="headText flex flex-col flex-wrap justify-center text-center base:text-right">
              <h2 className="text-2xl sm:text-3xl lg:text-4xl leading-normal sm:leading-normal lg:leading-normal font-bold">
                <span className="base:block inline-block">
                  A good product is produced at
                </span>
                <span className="base:block inline-block">
                  Allearth Korea Co., Ltd.
                </span>
              </h2>
            </div>
          </div>
          <div className="sectionBody grid grid-cols-1 md:grid-cols-2 base:gap-x-5 sm:gap-x-4 gap-x-2">
            <img
              src={image}
              alt="Allearth Korea ci image"
              className="max-w-sm md:max-w-none w-full mx-auto"
            />
            <div className="max-w-lg mx-auto pt-4 md:pt-0 text-sm md:text-lg lg:text-2xl space-y-4">
              <p>
                Allearth Korea Co., Ltd.'s motto is "delivering good products to
                customers".
              </p>
              <p>
                The circle in the symbol symbolizes the Earth and the alphabet
                "A" symbolizes the way people walk, meaning "we run to find good
                products on Earth and deliver them to customers."
              </p>
              <p>
                The three stars mean{" "}
                <span className="font-bold">
                  "customer needs," "customer value,"{" "}
                  <span className="font-normal">and</span> "customer empathy,"
                </span>{" "}
                which are the three goals of Allearth Korea Co., Ltd.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
