import "../../page.css";
import car from "../../../assets/images/products/carPage_en.jpg";
import { FOOTER_EN } from "../../../layout/eng/footer";
import { SUBHEADER_EN } from "../../../layout/eng/subheader";
export const CAR_EN = () => {
  return (
    <main id="content">
      <SUBHEADER_EN />
      <div className="w-full">
        <div className="max-w-screen-lg mx-auto py-20 lg:px-0 px-5">
          <img
            src={car}
            alt="상쾌한 드라이빙을 위해. 코로나바이러스 박멸! 인플루엔자A 박멸! 8가지 유해세균 박멸! 가장 더러운 핸들을 쾌적하게! 매일 만지는 핸들과 기어스틱, 당신의 차는 어떠신가요? 측정값이 885가 나오는 자동차 핸들 사진. 매일 샌퓨리 바이캅세정살균티슈를 사용한다면? 측정값 4가 나오는 이미지. 하루 한 번으로 바이러스와 세균으로부터 안전한 드라이빙. 컵홀더에 쏙! 모든 차종에 OK!  높이 200mm , 지름 80mm. 안전확인대상 생활화학제품 표시사항. 자세한 표기내용은 홈페이지에 기재된 곳으로 연락주세요 "
          />
        </div>
      </div>
      <FOOTER_EN />
    </main>
  );
};
