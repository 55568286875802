import { useState } from "react";
import "../page.css";
import ReactFullpage from "@fullpage/react-fullpage";
import { FOOTER_EN } from "../../layout/eng/footer";
import { HEADER_EN } from "../../layout/eng/header";
import { Intro_EN } from "../../parts/eng/sanpury/vicop_intro";
import { Output_EN } from "../../parts/eng/sanpury/vicop_output";
import { Slide_EN } from "../../parts/eng/sanpury/vicop_slide";
import { Vi_strength_EN } from "../../parts/eng/sanpury/vicop_strength";
import { Useful_EN } from "../../parts/eng/sanpury/vicop_usefull";
import { Instagram_EN } from "../../parts/eng/sanpury/vicop_instagram";
import { Instagram2_EN } from "../../parts/eng/sanpury/vicop_instagram2";

export const SANPURY_EN = () => {
  const [headerback, setHeaderBack] = useState<string>("var(--main1)");
  const [response, setresponse] = useState<boolean>(false);
  const headerbackHanlder = () => {
    setHeaderBack(() => {
      return "var(--main1)";
    });
  };

  const reactvieHandler = () => {
    if (window.innerWidth >= 1024) {
      setresponse(() => {
        return true;
      });
    } else {
      setresponse(() => {
        return false;
      });
    }
  };
  window.addEventListener("load", () => {
    headerbackHanlder();
    reactvieHandler();
  });
  window.addEventListener("resize", () => {
    reactvieHandler();
  });
  return response ? (
    <main id="content" className="pc banScroll">
      <HEADER_EN value={headerback} />
      <ReactFullpage
        licenseKey="F6396307-7ED243A8-996C8BAC-85EF3BC3"
        scrollingSpeed={500}
        render={() => {
          return (
            <ReactFullpage.Wrapper>
              <div className="section">
                <Intro_EN />
              </div>
              <div className="section">
                <Output_EN />
              </div>
              <div className="section">
                <Slide_EN />
              </div>
              <div className="section">
                <Vi_strength_EN />
              </div>
              <div className="section">
                <Useful_EN />
              </div>
              <div className="section">
                <Instagram_EN />
              </div>
              <div className="section fp-auto-height">
                <FOOTER_EN />
              </div>
            </ReactFullpage.Wrapper>
          );
        }}
      />
    </main>
  ) : (
    <main id="content" className="mobile">
      <HEADER_EN value={headerback} />
      <Intro_EN />
      <Output_EN />
      <Slide_EN />
      <Vi_strength_EN />
      <Useful_EN />
      <Instagram2_EN />
      <FOOTER_EN />
    </main>
  );
};
