import React, { useState } from "react";
import nubmer from "../../../assets/images/home/number1.png";
import image from "../../../assets/images/home/intro_image.png";
export const Intro = () => {
  return (
    <section>
      <div className="bg-white h-auto pb-20 base:pb-0 base:h-screen overflow-hidden flex flex-wrap content-center w-full pt-20 base:pt-24">
        <div className="lg:max-w-screen-lg lg:m-auto sm:m-6 m-4 base:max-w-none w-full">
          <div className="sectionHeader pb-8 md:pb-16 flex flex-wrap flex-col base:flex-row base:justify-between">
            <div className="numbering">
              <img
                src={nubmer}
                alt="1 회사소개"
                className="mx-auto base:m-0 h-36 base:h-auto"
              />
            </div>
            <div className="headText flex flex-col flex-wrap justify-center text-center base:text-right">
              <h2 className="text-2xl sm:text-3xl lg:text-5xl leading-normal lg:leading-normal sm:leading-normal font-bold">
                사람을 위해 사람이 뛰는 기업
              </h2>
              <p className="text-base sm:text-xl lg:text-2xl leading-normal sm:leading-normal lg:leading-normal">
                <span className="capitalize">company</span> people run for
                people
              </p>
            </div>
          </div>
          <div className="sectionBody grid grid-cols-1 md:grid-cols-2 base:gap-x-5 sm:gap-x-4 gap-x-2">
            <img
              src={image}
              alt="회사소개 이미지 , 사람3명이 책상에 앉아 회의하는 사진 "
              className="max-w-sm md:max-w-none w-full mx-auto"
            />
            <div className="max-w-lg mx-auto pt-4 md:pt-0 text-sm md:text-lg lg:text-2xl space-y-4">
              <p>
                (주)오러스코리아는 지구상 모든 소비자의 니즈를 파악하여 정직한
                상품을 만들어 널리 알려 판매하는 휴머니즘 기업입니다.
              </p>
              <p>
                항상 소비자의 입장에서 생각하고 믿을 수 있는 제품을 투명하고
                깨끗하게 현실적인 가격으로 만들어 나가겠습니다.
              </p>
              <p>
                (주)오러스 코리아의 목표는 '고객니즈', '고객가치', '고객공감'을
                같이 고민을 해결해주는 멀티라이프 스타일을 만들어 드립니다.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
