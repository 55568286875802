import "../layout.css";
import allearth from "../../assets/icon/allearth-star.png";
import kakaomarket from "../../assets/images/home/brand/sell_kakao.png";
import navermarket from "../../assets/images/home/brand/sell_naver.png";
import instagram from "../../assets/images/home/brand/sns_instagram.png";
import facebook from "../../assets/images/home/brand/sns_facebook.png";
import kakao from "../../assets/images/home/brand/sns_kakao.png";
export const FOOTER = () => {
  const notice = () => {
    alert("현재 준비 중입니다.");
  };
  return (
    <footer className="w-full py-5 sm:py-10">
      <div className="innerFooter max-w-screen-lg mx-auto">
        <div className="logos flex flex-wrap sm:flex-row flex-col gap-y-5 justify-between">
          <div>
            <img
              src={allearth}
              alt="오러스코리아 로고"
              className="h-20 mx-auto"
            />
          </div>
          <div className="flex flex-wrap content-center justify-center gap-x-4">
            <a
              target="_blank"
              href="https://www.instagram.com/sanpury_official/"
              className="inline-block w-10 sm:w-14"
            >
              <img src={instagram} alt="인스타그램 로고" className="w-full" />
            </a>
            <a
              target="_blank"
              href="https://www.facebook.com/profile.php?id=100074866286053"
              className="inline-block w-10 sm:w-14"
            >
              <img src={facebook} alt="페이스북 로고" className="w-full" />
            </a>
            <button onClick={notice} className="inline-block w-10 sm:w-14">
              <img
                src={kakaomarket}
                alt="카카오쇼핑하기 로고"
                className="w-full"
              />
            </button>
            <a
              target="_blank"
              href="https://smartstore.naver.com/sanpury"
              className="inline-block w-10 sm:w-14"
            >
              <img src={navermarket} alt="네이버쇼핑 로고" className="w-full" />
            </a>
            <button onClick={notice} className="inline-block w-10 sm:w-14">
              <img src={kakao} alt="카카오톡 로고" className="w-full" />
            </button>
          </div>
        </div>

        <address className="pt-10 leading-normal not-italic text-center sm:text-left space-y-2 text-sm sm:text-base">
          <p>
            <span className="inline-block rightGuide pr-3">
              회사명 : 오러스코리아
            </span>
            <span className="inline-block rightGuide px-3">대표 : 김우영</span>
            <span className="inline-block px-3">사업자번호 : 550-87-02047</span>
            <a
              target="_blank"
              href="https://teht.hometax.go.kr/websquare/websquare.html?w2xPath=/ui/ab/a/a/UTEABAAA13.xml"
              className="inline-block ml-3 border p-1"
            >
              사업자번호조희
            </a>
          </p>
          <p className="keep-all">
            대구광역시 동구 메디벨리로9, 3층 304-4호 (대림동, GS빌딩
            행복비즈니스센터)
          </p>
          <p>
            <span className="inline-block pr-3 rightGuide">
              통신판매업신고번호 : 2021-대구동구-0914
            </span>
            <span className="inline-block px-3 rightGuide">
              전화번호 : 010-6304-8899
            </span>
            <span className="inline-block pl-3 ">
              메일 : hahaha1033@nate.com
            </span>
          </p>
          <p>해외수출문의(International sales inquiry) : hahaha1033@nate.com</p>
          <p>
            <small className="uppercase text-base">
              copyright &copy; make allearth korea. all rights reserved
            </small>
          </p>
        </address>
      </div>
    </footer>
  );
};
