import car from "../../assets/images/sanpury/car.jpg";
import handle from "../../assets/images/sanpury/handle.jpg";
import camp from "../../assets/images/sanpury/camp.jpg";
import floor from "../../assets/images/sanpury/floor.jpg";
import hood from "../../assets/images/sanpury/hood.jpg";
import keyboard from "../../assets/images/sanpury/keyboard.jpg";

export const useful = [
  {
    index: 0,
    useName: "Car interior",
    image: car,
    desc: "A car that you ride every day, clean it up cleanly!",
  },
  {
    index: 1,
    useName: "Kitchen hood",
    image: hood,
    desc: "There's nothing like this to remove all kinds of dirt and oil",
  },
  {
    index: 2,
    useName: "Kitchen cooktop",
    image: floor,
    desc: "Kitchen cooktop where food or oil would have splashed! Use Sanpury Vi-Cop for cleaning!",
  },
  {
    index: 3,
    useName: "Camping Site",
    image: camp,
    desc: "A must-have camping item to prevent exposure to germs and viruses!",
  },
  {
    index: 4,
    useName: "Office keyboard",
    image: keyboard,
    desc: "How much do you clean your keyboard? Stop taking germs on your hands!",
  },
  {
    index: 5,
    useName: "Public handles",
    image: handle,
    desc: "Antibacterial films alone can't relieve us from anxiety about the handles that countless people would have grabbed and touched!",
  },
];

export const branch = [
  {
    index: 0,
    regionName: "Gwangju",
    branchName: "Gwangju headquarters",
    region: "o62",
    category: "mart1",
    address: "64, Biennale-ro, Yongbong-dong, Buk-gu, Gwangju",
    contact: "062-527-4852",
  },
  {
    index: 1,
    regionName: "Gwangju",
    branchName: "Gwangju distribution center branch",
    region: "o62",
    category: "mart1",
    address: "28, Seogwang-ro, Seo-gu, Gwangju (Maewol-dong)",
    contact: "062-654-5302",
  },
  {
    index: 2,
    regionName: "Jeollanam-do",
    branchName: "Yeonggwang Beopseongpo branch",
    region: "o61",
    category: "mart1",
    address: "13 Yeonggwang-ro, Beopseong-myeon, Yeonggwang-gun, Jeollanam-do",
    contact: "061-356-2122",
  },
  {
    index: 3,
    regionName: "Daejeon",
    branchName: "Yuseong-gu branch in Daejeon",
    region: "o42",
    category: "mart1",
    address: "295, Jijok-ro, Yuseong-gu, Daejeon",
    contact: "042-823-4850",
  },
  {
    index: 4,
    regionName: "Daejeon",
    branchName: "Daesung-dong branch in Daejeon",
    region: "o42",
    category: "mart1",
    address:
      "Daejeon, Dong-gu, Daejeon-ro 332, B104 on the first floor (Daesung-dong)",
    contact: "042-284-0990",
  },
];

export const ouput = [
  {
    title: "check 01",
    desc: "Removing 99.99% of Coronavirus & Influenza A",
  },
  {
    title: "check 02",
    desc: "It removes 99.9% of harmful bacteria",
  },
  {
    title: "check 03",
    desc: "Hypoallergenic for skin",
  },
  {
    title: "check 04",
    desc: "Powerful removal of oil and dirt",
  },
  {
    title: "check 05",
    desc: "Meltblown fabric",
  },
];
export const review = [
  {
    key: "review1",
    desc: "샌퓨리 바이캅 세정살균티슈 완전 강추해요!!!! 세상 안지워지던 묵은때들이 이렇게나 쉽게 지워지다니! 다른 제품 사서 돈 날리지 말고 당장 써보셔요 청소의 질이 달라집니다",
  },
  {
    key: "review2",

    desc: "티슈, 걸레질을 아무리 깨끗이 해도 닦이지 않는 것도 많고 마지막에 먼지들이 밀려서 청소 두 번 하는 기분이었는데 이 제품은 안 닦이는게 없고 먼지들이 남김없이 티슈에 착 붙어서 너무 좋아요!! 남는건 은은한 라벤더향 뿐~ 재구매 의사 200%입니다!!",
  },
  {
    key: "review3",
    desc: "보이는 곳, 안보이는 곳 닿기만 하면 지워지네요! 속이 다 시원해요 정말 ㅠㅠ 집에 놀러오는 사람들마다 보여줬더니 어디서 샀냐며 알려달라고 난리예요~ 알려줬더니 160매 이 가격 맞냐고 ㅋㅋ 좋은 가격에 좋은 제품 만들어주셔서 감사해요 ㅠㅠ 또 구매하러 올게요~!",
  },
];
