import { useful } from "../data";
export const Useful_EN = () => {
  return (
    <section>
      <div className="bg-white pb-20 base:pb-0 h-auto base:h-screen overflow-hidden flex flex-wrap content-center w-full pt-20 base:pt-24">
        <div className="lg:max-w-screen-lg lg:mx-auto sm:px-6 px-4 base:max-w-none w-full">
          <div className="sectionHeader pb-8 md:pb-14">
            <h2 className="text-2xl sm:text-3xl lg:text-4xl leading-normal sm:leading-normal lg:leading-normal font-bold text-center sm:text-left pb-4 md:pb-0 text-logo">
              <span className="uppercase">sanpury </span>that you can use with
              confidence
            </h2>
          </div>
          <div className="sectionBody grid grid-cols-2 base:grid-cols-3 base:gap-x-5 sm:gap-x-4 gap-x-2 gap-y-5">
            {useful.map((obj) => (
              <div key={obj.index} className="card w-full space-y-3">
                <p className="text-main1 font-bold text-xl md:text-3xl">
                  {obj.useName}
                </p>
                <div className="usefulRatio relative">
                  <img
                    className="absolute left-0 top-0 w-full h-full"
                    src={obj.image}
                    alt={`${obj.image} 사진`}
                  />
                </div>
                <p className="ellipsis h-auto base:h-20 text-base md:text-xl text-logo">
                  {obj.desc}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};
