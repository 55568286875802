import { makeVar } from "@apollo/client";
import { menu, menu_en } from "./layout/lay_func";

export const url = makeVar(document.location.pathname);

export const divide = (url: string) => {
  let sub = url;
  let main = url.split("/")[1];
  let values = {
    mainValue: "",
    mainIndex: 0,
    subValue: "",
    Text: "",
  };
  for (let i = 0; i < menu.length; i++) {
    let mainlink = menu[i].mainLink.split("/")[1];
    if (main === mainlink) {
      values.mainValue = menu[i].main;
      values.mainIndex = i;
      values.Text = menu[i].text;
      if (menu[i].sub.length > 0) {
        for (let j = 0; j < menu[i].sub.length; j++) {
          if (sub === menu[i].sub[j].links) {
            values.subValue = menu[i].sub[j].menus;
          }
        }
      }
    }
  }
  return values;
};
export const divide_en = (url: string) => {
  let sub = url;
  let main = url.split("/")[2];
  let values = {
    mainValue: "",
    mainIndex: 0,
    subValue: "",
    Text: "",
  };
  for (let i = 0; i < menu_en.length; i++) {
    let mainlink = menu_en[i].mainLink.split("/")[2];
    if (main === mainlink) {
      values.mainValue = menu_en[i].main;
      values.mainIndex = i;
      values.Text = menu_en[i].text;
      if (menu_en[i].sub.length > 0) {
        for (let j = 0; j < menu_en[i].sub.length; j++) {
          if (sub === menu_en[i].sub[j].links) {
            values.subValue = menu_en[i].sub[j].menus;
          }
        }
      }
    }
  }
  return values;
};
// export const en_divide = (url: string) => {
//   let sub = url;
//   let main = url.split("/")[2];
//   let values = {
//     mainValue: "",
//     mainIndex: 0,
//     subValue: "",
//     subback: "",
//   };
//   for (let i = 0; i < en_menu.length; i++) {
//     let mainlink = en_menu[i].mainLink.split("/")[2];
//     if (main === mainlink) {
//       values.mainValue = en_menu[i].main;
//       values.mainIndex = i;
//       if (en_menu[i].sub.length > 0) {
//         for (let j = 0; j < en_menu[i].sub.length; j++) {
//           if (sub === en_menu[i].sub[j].links) {
//             values.subValue = en_menu[i].sub[j].menus;
//             values.subback = en_menu[i].sub[j].subback;
//           }
//         }
//       }
//     }
//   }
//   return values;
// };
