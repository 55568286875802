import "../layout.css";
import logo from "../../assets/icon/logo.png";
import sanpury from "../../assets/icon/logo-sanpury.png";
import menu from "../../assets/icon/Hamburger_icon.svg";
import * as func from "../lay_func";
import { useReactiveVar } from "@apollo/client";
import { divide, url } from "../../apollo";
import { useState } from "react";
import close from "../../assets/icon/xicon.png";
import { MobileMenu } from "./mobileMenu";

export const HEADER = (prop: any) => {
  const headerback = {
    backgroundColor: prop.value,
  };
  const alarm = () => {
    alert("준비중입니다.");
  };
  const pathname = useReactiveVar(url);
  console.log(pathname);
  const [open, setopen] = useState<boolean>(false);
  const [line, setline] = useState<number>(9);
  const [mopen, setmopen] = useState<boolean>(false);
  const openHandler = (index: number) => {
    if (index === 2) {
      setopen(() => {
        return true;
      });
    }
    setline(() => {
      return index;
    });
  };
  const closeHandler = (index: number) => {
    if (index === 2 || index === 9) {
      setopen(() => {
        return false;
      });
    }
    setline(() => {
      return 9;
    });
  };
  const mopenHandler = () => {
    setmopen(() => {
      if (mopen) {
        return false;
      }
      return true;
    });
  };

  return (
    <div className="headerWrap fixed top-0 left-0 w-full z-20">
      <header
        id="header"
        className={`header-main transform ${open && "open"} ${
          headerback.backgroundColor === "var(--main1)"
            ? "text-white"
            : "text-black"
        }`}
        onMouseLeave={() => closeHandler(9)}
        style={headerback}
      >
        <div className="setlang max-w-screen-lg mx-auto h-4 hidden base:flex flex-wrap content-end justify-end ">
          <a
            href="/"
            className={`text-center text-xs font-bold mx-5 ${
              headerback.backgroundColor === "var(--main1)"
                ? "langLine_white"
                : "langLine_black"
            }`}
          >
            KOR
          </a>
          <a
            href={`/en${pathname}`}
            className="text-center text-xs font-bold mx-5 "
          >
            ENG
          </a>
        </div>
        <div className="innerheader max-w-screen-lg mx-auto h-20 flex flex-wrap base:justify-between justify-start px-3">
          <h1 className="w-40 flex flex-wrap content-center h-full base:h-20">
            {headerback.backgroundColor === "var(--main1)" ? (
              <a href="/sanpury">
                <img src={sanpury} alt="샌퓨리 로고" className="w-full" />
              </a>
            ) : (
              <a href="/">
                <img src={logo} alt="오러스코리아 로고" className="w-full" />
              </a>
            )}
          </h1>
          <div className="h-full menu base:hidden px-6 absolute right-0 flex flex-wrap content-center">
            <button onClick={mopenHandler}>
              <img
                src={menu}
                alt=" 메뉴열기버튼"
                className={`${
                  headerback.backgroundColor === "var(--main1)"
                    ? "menuInvert"
                    : ""
                }`}
              />
            </button>
          </div>
          <nav
            id="GNB"
            className="text-lg w-9/12  webnav base:inline-block hidden"
          >
            <ul className="inline-block w-full">
              {func.menu.map((obj) => (
                <li
                  className="inline-block relative w-1/5"
                  key={`${obj.main}mainmenu`}
                >
                  <a
                    href={obj.mainLink}
                    className={`${
                      headerback.backgroundColor === "var(--main1)"
                        ? "mainTabLine_white"
                        : "mainTabLine_black"
                    } mainTab h-20 flex-wrap flex content-center justify-center font-bold ${
                      (divide(pathname).mainValue === obj.main &&
                        "mainTabLine") ||
                      (line === obj.index && "mainTabLine")
                    }`}
                    onFocus={() => openHandler(obj.index)}
                    onMouseEnter={() => openHandler(obj.index)}
                    onMouseLeave={() => closeHandler(obj.index)}
                  >
                    {obj.main}
                  </a>
                  {func.menu[obj.index].sub.length > 0 && (
                    <ul
                      style={headerback}
                      className={`submenu absolute w-full text-center pb-3 ${
                        line === obj.index && "subtab"
                      }`}
                      onMouseEnter={() => openHandler(obj.index)}
                    >
                      {func.menu[obj.index].sub.map((obj) => (
                        <li key={obj.menus}>
                          <a
                            href={obj.links}
                            className=" text-base block hover:font-bold hover:text-main1 py-3 hover:bg-gray-300 hover:bg-opacity-30"
                          >
                            {obj.menus}
                          </a>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
              <li className="inline-block relative w-1/5" key={`mainmenu`}>
                <a
                  href="https://smartstore.naver.com/sanpury"
                  className={`mainTab h-20 flex-wrap flex content-center justify-center text-yellow-300 font-bold  ${
                    line === 4 && "mainTabLine"
                  }`}
                  onFocus={() => openHandler(4)}
                  onMouseEnter={() => openHandler(4)}
                >
                  Shop
                </a>
              </li>
            </ul>
          </nav>

          <div className={`${mopen ? "cutton-open" : "hidden"}`}></div>
          <nav
            id="MNB"
            className={`text-base w-full sm:max-w-xs bg-white base:hidden min-h-screen fixed mobilenav ${
              mopen ? "nav-open" : "nav-hide"
            }`}
          >
            <ul className="h-screen overflow-scroll noScrollBar">
              <li className="border-b border-main1 h-20 relative flex flex-wrap content-center justify-center">
                <div>
                  <img src={logo} alt="로고" width="100" />
                </div>
                <div className="h-full menu px-6 absolute right-0 flex flex-wrap content-center">
                  <button onClick={mopenHandler}>
                    <img width="25" src={close} alt=" 메뉴닫기 버튼" />
                  </button>
                </div>
              </li>
              <li className="h-14 flex flex-wrap px-10 justify-end content-center setlang border-b border-main1 text-black">
                <a href="/" className={`text-xs px-2 langLine_black font-bold`}>
                  KOR
                </a>
                <a href={`/en${pathname}`} className="text-xs px-2 font-bold">
                  ENG
                </a>
              </li>
              {func.menu.map((obj) => (
                <li
                  className="block relative w-full mobilelist text-black font-bold"
                  key={obj.index}
                >
                  {func.menu[obj.index].sub.length > 0 ? (
                    <MobileMenu value={obj} />
                  ) : (
                    <a
                      href={obj.mainLink}
                      className="mainTab h-20 flex-wrap flex content-center justify-center hover:bg-gray-200 focus:bg-gray-200"
                    >
                      {obj.main}
                    </a>
                  )}
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </header>
    </div>
  );
};
